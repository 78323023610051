import { Project, Simulation, SnackbarData, User } from '../types/interfaces';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  RefreshProjects = 'REFRESH_PROJECTS',
  AddProject = 'ADD_PROJECT',
  UpdateProject = 'UPDATE_PROJECT',

  RefreshUser = 'REFRESH_USER',
  SetUserImage = 'SET_USER_IMAGE',
  SetSharedUserImage = 'SET_SHARED_USER_IMAGE',
  SetCommenterImage = 'SET_COMMENTER_IMAGE',

  SetSelectedSimulation = 'SETSELECTED_SIMULATION',

  UpdateSnapshot = 'UPDATE_SNAPSHOT',
  UpdateFeedbackImage = 'UPDATE_FEEDBACKIMAGE',

  SetSnackbar = 'SET_SNACKBAR',

  StoreImage = 'STORE_IMAGE'
}

type ArbiraPayload = {
  [Types.RefreshProjects]: {
    projectList: Project[];
  };
  [Types.AddProject]: {
    project: Project;
  };
  [Types.UpdateProject]: {
    project: Project;
  };

  [Types.SetSelectedSimulation]: {
    simulation: Simulation;
  };

  [Types.RefreshUser]: {
    user: User;
  };

  [Types.SetUserImage]: {
    base64ImgString: string;
  };

  [Types.SetSharedUserImage]: {
    projectId: string;
    userId: string;
    base64ImgString: string;
  };

  [Types.SetCommenterImage]: {
    feedbackId: string;
    commentId: string;
    innerCommentId: string;
    base64ImgString: string;
  };

  [Types.UpdateSnapshot]: {
    projectId: string;
    simulationId: string;
    base64Image: string;
  };

  [Types.UpdateFeedbackImage]: {
    feedbackId: string;
    base64Image: string;
  };

  [Types.SetSnackbar]: {
    snackbarData: SnackbarData;
  };

  [Types.StoreImage]: {
    imageId: string;
    base64Image: string;
  };
};

export type ArbiraActions = ActionMap<ArbiraPayload>[keyof ActionMap<
  ArbiraPayload
>];

export const projectReducer = (state: Project[], action: ArbiraActions) => {
  let projectIndex = -1;
  switch (action.type) {
    // project related
    case Types.RefreshProjects:
      state = action.payload.projectList;
      return state;
    case Types.AddProject:
      return [...state, action.payload.project];
    case Types.UpdateProject:
      projectIndex = state.findIndex(
        project => project.id === action.payload.project.id
      );
      if (projectIndex !== -1) state[projectIndex] = action.payload.project;
      return state;
    case Types.UpdateSnapshot:
      projectIndex = state.findIndex(
        project => project.id === action.payload.projectId
      );
      let simulationIndex = -1;
      if (projectIndex !== -1) {
        simulationIndex = state[projectIndex].simulations.findIndex(
          simulation => simulation.id === action.payload.simulationId
        );
      }
      if (simulationIndex !== -1) {
        state[projectIndex].simulations[simulationIndex].downloadedImage =
          action.payload.base64Image;
        state[projectIndex].simulations[simulationIndex].snapshotLoaded = true;
      }
      return state;
    case Types.SetSharedUserImage:
      projectIndex = state.findIndex(
        project => project.id === action.payload.projectId
      );
      let sharedWithIndex = -1;
      if (projectIndex !== -1) {
        sharedWithIndex = state[projectIndex].sharedWith.findIndex(
          user => user.id === action.payload.userId
        );
      }
      if (sharedWithIndex !== -1) {
        state[projectIndex].sharedWith[sharedWithIndex].downloadedImage =
          action.payload.base64ImgString;
      }
      return state;
    default:
      return state;
  }
};

export const simulationReducer = (state: Simulation, action: ArbiraActions) => {
  let feedbackIndex = -1;
  switch (action.type) {
    case Types.SetSelectedSimulation:
      state = action.payload.simulation;
      return state;
    case Types.SetCommenterImage:
      feedbackIndex = state.feedbacks.findIndex(
        feedback => feedback.id === action.payload.feedbackId
      );
      if (feedbackIndex !== -1) {
        let commentIndex = -1;
        commentIndex = state.feedbacks[feedbackIndex].comments.findIndex(
          comment => comment.id === action.payload.commentId
        );
        if (commentIndex !== -1) {
          if (action.payload.innerCommentId === '')
            state.feedbacks[feedbackIndex].comments[
              commentIndex
            ].downloadedImage = action.payload.base64ImgString;
          else {
            let innerCommentIndex = -1;
            innerCommentIndex = state.feedbacks[feedbackIndex].comments[
              commentIndex
            ].comments.findIndex(
              comment => comment.id === action.payload.innerCommentId
            );
            if (innerCommentIndex !== -1) {
              state.feedbacks[feedbackIndex].comments[commentIndex].comments[
                innerCommentIndex
              ].downloadedImage = action.payload.base64ImgString;
            }
          }
        }
      }
      return state;
    case Types.UpdateFeedbackImage:
      feedbackIndex = state.feedbacks.findIndex(
        feedback => feedback.id === action.payload.feedbackId
      );
      if (feedbackIndex !== -1) {
        state.feedbacks[feedbackIndex].downloadedImage =
          action.payload.base64Image;
      }
      return state;
    default:
      return state;
  }
};

export const userReducer = (state: User, action: ArbiraActions) => {
  switch (action.type) {
    // user related
    case Types.RefreshUser:
      state = action.payload.user;
      return state;
    case Types.SetUserImage:
      state.downloadedImage = action.payload.base64ImgString;
      return state;
    default:
      return state;
  }
};

export const snackbarReducer = (state: SnackbarData, action: ArbiraActions) => {
  switch (action.type) {
    case Types.SetSnackbar:
      state = action.payload.snackbarData;
      return state;
    default:
      return state;
  }
};

export const imageDownloadReducer = (
  state: { [key: string]: string },
  action: ArbiraActions
) => {
  switch (action.type) {
    case Types.StoreImage:
      if (action.payload.imageId in state === false)
        state[action.payload.imageId] = action.payload.base64Image;
      return state;
    default:
      return state;
  }
};
