import React, { useContext, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { InviteUserModalProps } from '../../types/interfaces';
import {
  Box,
  Grid,
  InputLabel,
  IconButton,
  Hidden,
  AccordionSummary,
  ClickAwayListener
} from '@material-ui/core';
import { DeleteIcon, CloseIcon } from '../reusable/IconImages';
import { MainButton, CancelButton } from '../reusable/Buttons';
import {
  displayBlock,
  fullScreenModalBackContainerStyle,
  fullScreenModalBackgroundStyle,
  modalCloseButtonBoxStyle,
  modalCloseButtonStyle,
  modalCloseTextStyle,
  modalInputLabelTextStyle,
  whiteBackgroundInputWindow,
  modalTitleStyleSmallerTopPadding,
  projectEditAccordionHeaderStyle,
  bottomButtonModalStyle
} from '../../styles/styles';
import { HorizontalDivider } from '../reusable/HorizontalDivider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SharedWithItem } from './SharedWithItem';
import {
  CustomChipInput,
  CustomChip,
  CustomAccordion,
  CustomAccordionDetails,
  CustomInputWithFocus
} from '../reusable/CustomInputs';
import { getProject } from '../../helper/helper';
import { AppContext } from '../../context/context';
import { Types } from '../../reducers/reducers';
import { ShareProject, GetProject } from '../../helper/apiHelper';
import {
  RefreshSimulationCoverImage,
  RefreshSharedWithUserImage
} from '../../helper/imageHelper';

export function InviteUserModal(props: InviteUserModalProps) {
  const { state, dispatch } = useContext(AppContext);
  let selectedProject = getProject(state.projectList, props.projectId);
  const [newContacts, setNewContacts] = useState<string[]>([]);
  const [messageText, setMessageText] = useState('');
  const [borderColor, setBorderColor] = useState('1px solid #A8A6AE');

  function handleAddContact(contact: string) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (contact.match(validRegex)) {
      setNewContacts(newContacts.concat(contact));
    } else {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'E-mail address is not valid.',
            severity: 'error'
          }
        }
      });
    }
  }

  function handleDeleteContact(contactToRemove: string) {
    setNewContacts(newContacts.filter(contact => contact !== contactToRemove));
  }

  async function handleSave() {
    var filteredContacts = newContacts;
    selectedProject.sharedWith.forEach(element => {
      filteredContacts = filteredContacts.filter(
        contact => contact !== element.emailOfUser
      );
    });
    if (filteredContacts.length > 0) {
      await ShareProject(
        props.projectId,
        filteredContacts,
        messageText,
        dispatch
      );
      let newProject = await GetProject(props.projectId, dispatch);
      dispatch({
        type: Types.UpdateProject,
        payload: {
          project: newProject
        }
      });
      RefreshSimulationCoverImage(newProject, dispatch, state.downloadedImages);
      RefreshSharedWithUserImage(newProject, dispatch, state.downloadedImages);
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Project shared successfully.',
            severity: 'success'
          }
        }
      });
      cleanInputFields();
      props.handleClose();
    } else if (newContacts.length > 0 && filteredContacts.length === 0) {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'The e-mail address/adresses is/are already invited.',
            severity: 'error'
          }
        }
      });
    } else {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'At least one valid e-mail address is mandatory.',
            severity: 'error'
          }
        }
      });
    }
  }

  function cleanInputFields() {
    setNewContacts([]);
    setMessageText('');
  }

  function handleCancel() {
    cleanInputFields();
    props.handleClose();
  }

  function handleClickAway() {
    if (newContacts.length === 0) {
      setBorderColor('1px solid #BD2031');
    } else {
      setBorderColor('1px solid #A8A6AE');
    }
  }

  function handleClickOnChipInput() {
    setBorderColor('1px solid #292336');
  }

  function handleMessageTextChange(value: string) {
    setMessageText(value);
  }

  return (
    <Modal open={props.isOpen}>
      <Box style={fullScreenModalBackgroundStyle}>
        <Hidden mdDown>
          <Box style={modalCloseButtonBoxStyle}>
            <Grid container justifyContent="center" style={displayBlock}>
              <Grid item xs={12}>
                <IconButton
                  style={modalCloseButtonStyle}
                  color="default"
                  disableFocusRipple={true}
                >
                  <CloseIcon clicked={handleCancel} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box style={modalCloseTextStyle}>CLOSE</Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={fullScreenModalBackContainerStyle}
        >
          <Grid
            item
            xs={12}
            container
            style={{ maxWidth: '100%' }}
            justifyContent="center"
          >
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={whiteBackgroundInputWindow}
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: '10px',
                  marginLeft: '10%',
                  marginRight: '10%'
                }}
              >
                <Grid item xs={12} container justifyContent="center">
                  <Box
                    style={modalTitleStyleSmallerTopPadding}
                    fontWeight={700}
                  >
                    Invite user
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Invite
                  </InputLabel>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <CustomChipInput
                      placeholder="E-mail address..."
                      value={newContacts}
                      style={{
                        height: '64px',
                        paddingBottom: '2px',
                        marginBottom: '20px',
                        border: borderColor
                      }}
                      onAdd={(contact: string) => handleAddContact(contact)}
                      onClick={handleClickOnChipInput}
                      chipRenderer={({ value }: any, key: any) => (
                        <CustomChip
                          key={key}
                          className="chip"
                          clickable={false}
                          label={value}
                          onDelete={() => handleDeleteContact(value)}
                          deleteIcon={
                            <DeleteIcon
                              clicked={handleDeleteContact}
                              value={value}
                            />
                          }
                        />
                      )}
                    />
                  </ClickAwayListener>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Message
                  </InputLabel>
                  <CustomInputWithFocus
                    placeholder="Message..."
                    minRows={2}
                    maxRows={2}
                    multiline={true}
                    style={{ marginTop: '-3px' }}
                    value={messageText}
                    onChange={(e: { target: { value: string } }) =>
                      handleMessageTextChange(e.target.value)
                    }
                  />
                </Grid>
                <Grid item container sm={12}>
                  <CustomAccordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon htmlColor="#292336" fontSize="small" />
                      }
                    >
                      <Box style={projectEditAccordionHeaderStyle}>
                        SHARED WITH {selectedProject.sharedWith.length} PEOPLE
                      </Box>
                    </AccordionSummary>
                    <CustomAccordionDetails>
                      <Grid item sm={12}>
                        <Box style={{ paddingBottom: '10px' }}>
                          <HorizontalDivider />
                        </Box>
                        <Grid
                          container
                          item
                          xs={12}
                          style={{
                            overflow: 'scroll',
                            maxHeight: '220px'
                          }}
                        >
                          {selectedProject.sharedWith.map(sharedWith => (
                            <SharedWithItem
                              key={sharedWith.userId}
                              sharedWith={sharedWith}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </CustomAccordionDetails>
                  </CustomAccordion>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container style={bottomButtonModalStyle}>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{
                padding: '0px 10px 0px 0px'
              }}
            >
              <Box style={{ width: '120px' }}>
                <CancelButton
                  textValue="CANCEL"
                  height="32px"
                  onclick={handleCancel}
                ></CancelButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
              alignItems="center"
              style={{ padding: '0px 0px 0px 10px' }}
            >
              <Box
                style={{
                  width: '120px'
                }}
              >
                <MainButton
                  textValue="SAVE"
                  height="32px"
                  onclick={handleSave}
                ></MainButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
