import { Grid } from '@material-ui/core';
import React from 'react';
import { SimulationSettingsMenuDialogProps } from '../../types/interfaces';
import { LightMenuButtonHover } from '../reusable/Buttons';
import { StyledMenuTopRight } from '../reusable/StyledMenu';

export function SimulationSettingsMenuDialog(
  props: SimulationSettingsMenuDialogProps
) {
  return (
    <StyledMenuTopRight
      keepMounted
      open={props.isOpen}
      onClose={props.handleClose}
      anchorEl={props.menuAnchor}
    >
      <Grid
        style={{ width: '230px', padding: '0px 10px' }}
        xs={12}
        container
        item
      >
        <Grid xs={12} item style={{ padding: '5px 0px' }}>
          <LightMenuButtonHover
            textValue="Edit"
            height="3.3rem"
            onclick={props.handleSimulationUpdatePressed}
          />
        </Grid>
      </Grid>
    </StyledMenuTopRight>
  );
}
