import { withStyles } from '@material-ui/styles';

import {
  createStyles,
  InputBase,
  Chip,
  Accordion,
  AccordionDetails
} from '@material-ui/core';

import ChipInput from 'material-ui-chip-input';

export const CustomInput = withStyles(() =>
  createStyles({
    root: {
      'label + &': {
        marginTop: '3px',
        width: '100%'
      }
    },
    input: {
      borderRadius: 2,
      backgroundColor: '#FFFFFF',
      border: '1px solid #A8A6AE',
      padding: '10px 15px 10px 15px',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      width: '100%',
      fontFamily: 'Prompt',
      color: '#292336'
    }
  })
)(InputBase);

export const CustomInputWithFocus = withStyles(() =>
  createStyles({
    input: {
      '&:focus': {
        border: '1px solid #292336'
      },
      '&:invalid': {
        border: '1px solid #BD2031'
      }
    }
  })
)(CustomInput);

export const CustomChipInput = withStyles(() =>
  createStyles({
    root: {
      'label + &': {
        marginTop: '3px',
        width: '100%',
        borderRadius: 2,
        backgroundColor: '#FFFFFF',
        border: '1px solid #A8A6AE'
      }
    },
    input: {
      fontFamily: 'Prompt',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      color: '#292336',
      width: '100%',
      padding: '0px 0px 10px 0px'
    },
    chipContainer: {
      '&::before': {
        content: 'none'
      },
      '&::after': {
        content: 'none'
      },
      padding: '6px 15px 0px 15px',
      minHeight: '36px',
      overflow: 'scroll'
    }
  })
)(ChipInput);

export const CustomChip = withStyles(() =>
  createStyles({
    root: {
      fontFamily: 'Prompt',
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
      color: '#FFFFFF',
      backgroundColor: '#A8A6AE',
      border: '1px solid #A8A6AE',
      boxSizing: 'border-box',
      borderRadius: '31px',
      height: '2.8rem',
      margin: '0px 6px 6px 0px'
    }
  })
)(Chip);

export const CustomAccordion = withStyles(() =>
  createStyles({
    root: {
      boxShadow: 'none',
      backgroundColor: '#F7F7F7',
      width: '100%'
    }
  })
)(Accordion);

export const CustomAccordionDetails = withStyles(() =>
  createStyles({
    root: {
      padding: '0px 24px 24px'
    }
  })
)(AccordionDetails);
