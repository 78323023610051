import { SharedWithItemProps } from '../../types/interfaces';
import { Grid, Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { UserIcon } from '../reusable/IconImages';

export function SharedWithItem(props: SharedWithItemProps) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      boxStyle: {
        [theme.breakpoints.down('sm')]: { maxWidth: '150px' },
        [theme.breakpoints.up('md')]: { maxWidth: '250px' }
      }
    })
  );
  const classes = useStyles();

  var ReactOverflowTooltip = require('react-overflow-tooltip');

  return (
    <Grid container item xs={12} style={{ padding: '5px 0px' }}>
      <Grid item xs={1} container alignItems="center">
        <UserIcon
          userId={props.sharedWith.userId}
          firstNameOfUser={props.sharedWith.firstNameOfUser}
          lastNameOfUser={props.sharedWith.lastNameOfUser}
          imgPathOfUser={props.sharedWith.imgPathOfUser}
          downloadedImage={props.sharedWith.downloadedImage}
          emailOfUser={props.sharedWith.emailOfUser}
          height={'30px'}
          marginLeft={'0px'}
        />
      </Grid>
      <Grid
        item
        xs={11}
        style={{ paddingLeft: '15px' }}
        container
        alignItems="center"
      >
        <Box
          style={{ fontFamily: 'Prompt', fontSize: '1.4rem', color: '#292336' }}
        >
          <Box className={classes.boxStyle}>
            <ReactOverflowTooltip title={props.sharedWith.emailOfUser}>
              <div
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {props.sharedWith.emailOfUser}
              </div>
            </ReactOverflowTooltip>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
