import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  ButtonBase,
  createStyles,
  withStyles
} from '@material-ui/core';
import {
  sideMenuItemButtonStyle,
  sideMenuItemBoxStyle,
  sideMenuItemNameStyle,
  sideMenuItemMoreButton,
  sideMenuItemSelectedStyle,
  sideMenuItemStyle,
  sideMenuItemDetails
} from '../../styles/styles';
import { SideMenuItemProps, ViewMode } from '../../types/interfaces';
import { MoreIcon, RedEllipseIcon } from '../reusable/IconImages';
import { ProjectEditModal } from '../project/ProjectEditModal';
import { SideMenuItemMenuDialog } from './SideMenuItemMenuDialog';
import { InviteUserModal } from '../project/InviteUserModal';
import { RedHorizontalLine } from '../reusable/HorizontalDivider';
import { Types } from '../../reducers/reducers';
import { AppContext } from '../../context/context';
import { getProject } from '../../helper/helper';
import { ArchiveProject, GetProject } from '../../helper/apiHelper';

const HoveredBox = withStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: '#efefef70'
      },
      backgroundColor: '#ffffff'
    }
  })
)(Box);

export function SideMenuItem(props: SideMenuItemProps) {
  const { state, dispatch } = useContext(AppContext);
  let project = getProject(state.projectList, props.projectId);
  const [menuPopupOpen, setMenuPopupOpen] = useState(false);
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  function handleClick() {
    handleMenuPopupClose();
    props.onProjectSelectionChanged(props.projectId);
    props.onViewModeChange(ViewMode.ProjectPage);
  }

  function handleMenuPopupOpen(target: any) {
    setMenuAnchor(target);
    setMenuPopupOpen(true);
  }

  function handleMenuPopupClose() {
    setMenuAnchor(null);
    setMenuPopupOpen(false);
  }

  function handleEditPopupOpen() {
    handleMenuPopupClose();
    setEditPopupOpen(true);
  }

  function handleSharePopupOpen() {
    handleMenuPopupClose();
    setSharePopupOpen(true);
  }

  async function handleArchiveProject() {
    handleMenuPopupClose();
    await ArchiveProject(props.projectId, dispatch);
    dispatch({
      type: Types.UpdateProject,
      payload: {
        project: await GetProject(props.projectId, dispatch)
      }
    });
    if (props.selectedProjectId === props.projectId)
      props.onProjectSelectionChanged('');
  }

  return (
    <HoveredBox
      style={
        props.projectId === props.selectedProjectId
          ? sideMenuItemSelectedStyle
          : sideMenuItemStyle
      }
    >
      <Box
        style={
          props.projectId === props.selectedProjectId
            ? {
                position: 'relative'
              }
            : { display: 'none' }
        }
      >
        <Box
          style={{
            position: 'absolute',
            right: '-23px',
            bottom: '-28px'
          }}
        >
          <Grid container>
            <RedEllipseIcon />
            <Box style={{ width: '20px', paddingTop: '2px' }}>
              <RedHorizontalLine />
            </Box>
          </Grid>
        </Box>
      </Box>
      <Grid key={props.projectId} container item xs={12}>
        <Grid item xs={10}>
          <ButtonBase
            key={props.projectId}
            onClick={handleClick}
            disableRipple={true}
            style={sideMenuItemButtonStyle}
          >
            <div style={sideMenuItemBoxStyle}></div>
            <div style={sideMenuItemNameStyle}>{project.name}</div>
          </ButtonBase>
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <ButtonBase
            style={sideMenuItemMoreButton}
            disableRipple={true}
            onClick={event => {
              let target = event.currentTarget;
              handleMenuPopupOpen(target);
            }}
          >
            <MoreIcon />
          </ButtonBase>
          <SideMenuItemMenuDialog
            isOpen={menuPopupOpen}
            menuAnchor={menuAnchor}
            handleClose={handleMenuPopupClose}
            handleOpenProject={handleClick}
            handleEditProject={handleEditPopupOpen}
            handleShareProject={handleSharePopupOpen}
            handleArchiveProject={handleArchiveProject}
          />
          <ProjectEditModal
            projectId={props.projectId}
            isOpen={editPopupOpen}
            handleClose={() => setEditPopupOpen(false)}
          />
          <InviteUserModal
            projectId={props.projectId}
            sharedWith={project.sharedWith}
            isOpen={sharePopupOpen}
            handleClose={() => setSharePopupOpen(false)}
          />
        </Grid>
      </Grid>
      <Grid
        style={
          props.projectId === props.selectedProjectId
            ? {
                ...sideMenuItemDetails,
                position: 'relative'
              }
            : {
                ...sideMenuItemDetails,
                display: 'none'
              }
        }
      >
        <div>Created by:</div>
        <div style={{ marginTop: '4px', fontWeight: 600, color: '#292336' }}>
          {project.firstNameOfUser +
            ' ' +
            project.lastNameOfUser +
            ' ' +
            new Date(project.creationTime).toLocaleDateString()}
        </div>
        <div style={{ marginTop: '16px', overflowWrap: 'break-word' }}>
          {project.description}
        </div>
      </Grid>
    </HoveredBox>
  );
}
