import React, { ChangeEvent, useContext, useState } from 'react';
import {
  FileType,
  NewSimulationModalProps,
  UploadViewMode,
  UploadViewState
} from '../../types/interfaces';
import {
  Modal,
  Box,
  Grid,
  IconButton,
  InputLabel,
  Hidden
} from '@material-ui/core';
import {
  modalCloseButtonBoxStyle,
  modalCloseButtonStyle,
  modalCloseTextStyle,
  renderingText,
  uploadSuccessTitle,
  newSimulationUploadTextStyle,
  newSimulationUploadTextBoldStyle,
  fullScreenModalBackgroundStyle,
  fullScreenModalBackContainerStyle,
  displayBlock,
  modalInputLabelTextStyle,
  whiteBackgroundInputWindow,
  modalTitleStyleSmallerTopPadding,
  bottomButtonModalStyle
} from '../../styles/styles';
import { CheckMarkCircle, CloseIcon } from '../reusable/IconImages';
import {
  /*DiscardButton,*/ MainButton,
  CancelButton
} from '../reusable/Buttons';

import { CustomInputWithFocus } from '../reusable/CustomInputs';
import { ModalUploadGrid } from '../reusable/ModalUploadGrid';
import { Types } from '../../reducers/reducers';
import { AppContext } from '../../context/context';
import { getProject } from '../../helper/helper';
import { AddSimulation, GetProject, UploadFile } from '../../helper/apiHelper';
import {
  RefreshSimulationCoverImage,
  RefreshSharedWithUserImage
} from '../../helper/imageHelper';

export function NewSimulationModal(props: NewSimulationModalProps) {
  const { state, dispatch } = useContext(AppContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [simulationFile, setSimulationFile] = useState<File>({} as File);
  const [uploadProcessValue, setUploadProcessValue] = useState(0);
  const [uploadViewState, setUploadViewState] = useState(
    UploadViewState.Initial
  );
  const [uploadViewMode, setUploadViewMode] = useState(UploadViewMode.Upload);

  let interval: any;

  function handleFileSelected(event: ChangeEvent<HTMLInputElement>) {
    let files = event.target.files;
    if (files !== null) {
      setSimulationFile(files[0]);
      setUploadViewState(UploadViewState.Finished);
    }
  }

  function fileUploadCanceled() {
    clearInterval(interval);
    setUploadProcessValue(0);
    setUploadViewState(UploadViewState.Initial);
  }

  async function handleSave() {
    if (name.length > 0 && uploadViewState === UploadViewState.Finished) {
      let blobSimulationFile = await UploadFile(
        simulationFile,
        FileType.Simulation,
        dispatch
      );
      let project = getProject(state.projectList, props.selectedProjectId);
      await AddSimulation(
        project.id,
        project.name,
        name,
        description,
        state.loggedInUser,
        blobSimulationFile,
        dispatch
      );
      let newProject = await GetProject(project.id, dispatch);
      dispatch({
        type: Types.UpdateProject,
        payload: {
          project: newProject
        }
      });
      RefreshSimulationCoverImage(newProject, dispatch, state.downloadedImages);
      RefreshSharedWithUserImage(newProject, dispatch, state.downloadedImages);
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Simulation uploaded successfully.',
            severity: 'success'
          }
        }
      });
      setUploadViewMode(UploadViewMode.UploadSuccess);
    } else {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Simulation title and a file are mandatory.',
            severity: 'error'
          }
        }
      });
    }
  }

  function handleCancel() {
    clearInputFields();
    props.handleClose();
  }

  function clearInputFields() {
    setName('');
    setDescription('');
    setSimulationFile({} as File);
    setUploadProcessValue(0);
    setUploadViewState(UploadViewState.Initial);
  }

  function handleDone() {
    clearInputFields();
    setUploadViewMode(UploadViewMode.Upload);
    props.handleClose();
  }

  let uploadModal = null;
  let bottomButtons = null;
  if (uploadViewMode === UploadViewMode.Upload) {
    uploadModal = (
      <Grid
        item
        container
        xs={12}
        sm={6}
        style={whiteBackgroundInputWindow}
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: '30px',
            marginLeft: '10%',
            marginRight: '10%'
          }}
        >
          <Grid item xs={12} container justifyContent="center">
            <Box style={modalTitleStyleSmallerTopPadding} fontWeight={700}>
              Upload simulation
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <InputLabel shrink style={modalInputLabelTextStyle}>
              Simulation title
            </InputLabel>
            <CustomInputWithFocus
              required
              placeholder="Enter simulation name..."
              style={{ height: '40px', marginBottom: '40px' }}
              value={name}
              onChange={(e: { target: { value: string } }) =>
                setName(e.target.value)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <ModalUploadGrid
              onChange={handleFileSelected}
              onFileUploadCanceled={fileUploadCanceled}
              uploadProcessValue={uploadProcessValue}
              uploadViewState={uploadViewState}
              fileName={simulationFile.name}
              uploadText={'UPLOAD SIMULATION FILE'}
              supportedFormats={'*.xyz, *.fgh, *.kju'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: '12px',
              paddingBottom: '15px',
              display: 'flex',
              justifyContent: 'flex-start'
            }}
            container
            direction="column"
            justifyContent="flex-end"
          >
            <InputLabel shrink style={modalInputLabelTextStyle}>
              Simulation description
            </InputLabel>
            <CustomInputWithFocus
              placeholder="Enter description..."
              minRows={2}
              maxRows={2}
              multiline={true}
              style={{ padding: '0px' }}
              value={description}
              onChange={(e: { target: { value: string } }) =>
                setDescription(e.target.value)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
    bottomButtons = (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        style={bottomButtonModalStyle}
      >
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ padding: '0px 10px 0px 0px' }}
        >
          <Box style={{ width: '120px' }}>
            <CancelButton
              textValue="CANCEL"
              height="32px"
              onclick={handleCancel}
            ></CancelButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{ padding: '0px 0px 0px 10px' }}
        >
          <Box
            style={{
              width: '120px'
            }}
          >
            <MainButton
              textValue="DONE"
              height="32px"
              onclick={handleSave}
            ></MainButton>
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    uploadModal = (
      <Grid
        item
        container
        xs={12}
        sm={6}
        style={whiteBackgroundInputWindow}
        justifyContent="center"
      >
        <Grid item xs={12} container justifyContent="center">
          <Box
            style={{
              paddingTop: '26px'
            }}
            position={'absolute'}
          >
            <CheckMarkCircle />
          </Box>
          <Box
            style={uploadSuccessTitle}
            fontWeight={700}
            position={'absolute'}
          >
            Upload Success
          </Box>
          <Box style={renderingText} position={'absolute'}>
            Rendering time aprox. 1 hour... please wait for it!
          </Box>
        </Grid>
        <Grid item xs={12} container>
          <Box paddingTop="180px">
            <InputLabel style={newSimulationUploadTextStyle}>
              Simulation title
            </InputLabel>
            <Box style={newSimulationUploadTextBoldStyle}>{name}</Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ paddingTop: '10px' }}
          container
          direction="column"
        >
          <InputLabel style={newSimulationUploadTextStyle}>
            Simulation description
          </InputLabel>
          <Box style={newSimulationUploadTextBoldStyle}>{description}</Box>
        </Grid>
      </Grid>
    );
    bottomButtons = (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={bottomButtonModalStyle}
      >
        <Box
          style={{
            width: '120px'
          }}
        >
          <MainButton
            textValue="OK"
            height="32px"
            onclick={handleDone}
          ></MainButton>
        </Box>
      </Grid>
    );
  }

  return (
    <Modal open={props.isOpen}>
      <Box style={fullScreenModalBackgroundStyle}>
        <Hidden mdDown>
          <Box style={modalCloseButtonBoxStyle}>
            <Grid container justifyContent="center" style={displayBlock}>
              <Grid item xs={12}>
                <IconButton
                  style={modalCloseButtonStyle}
                  color="default"
                  disableFocusRipple={true}
                >
                  <CloseIcon clicked={handleCancel} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box style={modalCloseTextStyle}>CLOSE</Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={fullScreenModalBackContainerStyle}
        >
          <Grid
            item
            xs={12}
            container
            style={{ maxWidth: '100%' }}
            justifyContent="center"
          >
            {uploadModal}
          </Grid>
          {bottomButtons}
        </Grid>
      </Box>
    </Modal>
  );
}
