import { createContext, Dispatch, useReducer } from 'react';
import React from 'react';
import {
  ArbiraActions,
  projectReducer,
  userReducer,
  simulationReducer,
  snackbarReducer,
  imageDownloadReducer
} from '../reducers/reducers';
import { AppProps, Simulation } from '../types/interfaces';
import { Color } from '@material-ui/lab/Alert';

const initialState = {
  projectList: [],
  selectedSimulation: {} as Simulation,
  loggedInUser: {
    id: '',
    firstName: '',
    lastName: '',
    company: '',
    imgPath: '',
    downloadedImage: '',
    email: ''
  },
  snackbarData: {
    open: false,
    message: '',
    severity: 'info' as Color
  },
  downloadedImages: {} as { [key: string]: string }
};

const AppContext = createContext<{
  state: AppProps;
  dispatch: Dispatch<ArbiraActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  {
    projectList,
    selectedSimulation,
    loggedInUser,
    snackbarData,
    downloadedImages
  }: AppProps,
  action: ArbiraActions
) => ({
  projectList: projectReducer(projectList, action),
  selectedSimulation: simulationReducer(selectedSimulation, action),
  loggedInUser: userReducer(loggedInUser, action),
  snackbarData: snackbarReducer(snackbarData, action),
  downloadedImages: imageDownloadReducer(downloadedImages, action)
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
export { AppProvider, AppContext };
