import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Grid,
  IconButton,
  InputLabel,
  Hidden
} from '@material-ui/core';
import {
  //maybe make these styles more general so they don't apply only on the new robot modal
  modalCloseButtonBoxStyle,
  displayBlock,
  modalCloseButtonStyle,
  modalCloseTextStyle,
  fullScreenModalBackgroundStyle,
  fullScreenModalBackContainerStyle,
  topMenuButtonWithNoPadding,
  modalInputLabelTextStyle,
  whiteBackgroundInputWindow,
  bottomButtonModalStyle
} from '../../styles/styles';
import { MainButton, CancelButton } from '../reusable/Buttons';
import { AccountSettingsModalProps, FileType } from '../../types/interfaces';
import { CloseIcon, UserIcon } from '../reusable/IconImages';
import { CustomInputWithFocus } from '../reusable/CustomInputs';
import { AppContext } from '../../context/context';
import { Types } from '../../reducers/reducers';
import {
  CreateBase64StrFromFile,
  GetUser,
  UpdateUser,
  UploadBase64File
} from '../../helper/apiHelper';

export const AccountSettingsModal = (props: AccountSettingsModalProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [firstName, setFirstName] = useState(state.loggedInUser.firstName);
  const [lastName, setLastName] = useState(state.loggedInUser.lastName);
  const [company, setCompany] = useState(state.loggedInUser.company);
  const [userImage, setUserImage] = useState(
    state.loggedInUser.downloadedImage
  );
  const [userImageFileName, setUserImageName] = useState('');
  const [userImageChanged, setUserImageChanged] = useState(false);

  async function handleSave() {
    if (firstName.length > 0 && lastName.length > 0) {
      let newImageName = state.loggedInUser.imgPath;
      if (userImageChanged) {
        newImageName = await UploadBase64File(
          userImage,
          userImageFileName,
          FileType.UserImage,
          dispatch
        );
      }
      await UpdateUser(
        state.loggedInUser.id,
        firstName,
        lastName,
        company,
        newImageName,
        dispatch
      );
      dispatch({
        type: Types.RefreshUser,
        payload: {
          user: await GetUser(state.loggedInUser.id, dispatch)
        }
      });
      dispatch({
        type: Types.SetUserImage,
        payload: {
          base64ImgString: userImage
        }
      });
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'User updated successfully.',
            severity: 'success'
          }
        }
      });
      props.handleClose();
    } else {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Firstname and lastname are mandatory.',
            severity: 'error'
          }
        }
      });
    }
  }

  async function handleImageSelected() {
    const inputImage = document.getElementById(
      'uploadImage'
    ) as HTMLInputElement;
    if (inputImage != null && inputImage.files != null) {
      setUserImage(
        (await CreateBase64StrFromFile(inputImage.files[0])) as string
      );
      setUserImageName(inputImage.files[0].name);
      setUserImageChanged(true);
    }
  }

  function handleCancel() {
    setFirstName(state.loggedInUser.firstName);
    setLastName(state.loggedInUser.lastName);
    setCompany(state.loggedInUser.company);
    setUserImage(state.loggedInUser.downloadedImage);
    props.handleClose();
  }

  useEffect(() => {
    setFirstName(state.loggedInUser.firstName);
    setLastName(state.loggedInUser.lastName);
    setCompany(state.loggedInUser.company);
  }, [state.loggedInUser]);

  return (
    <Modal open={props.isOpen}>
      <Box style={fullScreenModalBackgroundStyle}>
        <Hidden mdDown>
          <Box style={modalCloseButtonBoxStyle}>
            <Grid container justifyContent="center" style={displayBlock}>
              <Grid item xs={12}>
                <IconButton
                  style={modalCloseButtonStyle}
                  color="default"
                  disableFocusRipple={true}
                >
                  <CloseIcon clicked={handleCancel} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box style={modalCloseTextStyle}>CLOSE</Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={fullScreenModalBackContainerStyle}
        >
          <Grid
            item
            xs={12}
            container
            style={{ maxWidth: '100%' }}
            justifyContent="center"
          >
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={whiteBackgroundInputWindow}
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: '30px',
                  marginLeft: '10%',
                  marginRight: '10%'
                }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{ marginBottom: '50px' }}
                >
                  <IconButton
                    style={topMenuButtonWithNoPadding}
                    color="default"
                    disableRipple={true}
                  >
                    <label htmlFor="uploadImage">
                      <UserIcon
                        userId={state.loggedInUser.id}
                        firstNameOfUser={state.loggedInUser.firstName}
                        lastNameOfUser={state.loggedInUser.lastName}
                        imgPathOfUser={state.loggedInUser.imgPath}
                        downloadedImage={userImage}
                        emailOfUser={state.loggedInUser.email}
                        height={'80px'}
                        marginLeft={'0px'}
                      />
                    </label>
                    <input
                      id="uploadImage"
                      type="file"
                      accept="image/*"
                      style={{ visibility: 'hidden', height: 0 }}
                      onChange={handleImageSelected}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={12} container style={{ marginTop: '30px' }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <InputLabel shrink style={modalInputLabelTextStyle}>
                      Firstname
                    </InputLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <CustomInputWithFocus
                      required
                      placeholder="Enter firstname..."
                      style={{ height: '4rem', width: '100%' }}
                      value={firstName}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setFirstName(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{ marginTop: '30px' }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <InputLabel shrink style={modalInputLabelTextStyle}>
                      Lastname
                    </InputLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <CustomInputWithFocus
                      required
                      placeholder="Enter lastname..."
                      style={{ height: '4rem', width: '100%' }}
                      value={lastName}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setLastName(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{ marginTop: '30px' }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <InputLabel shrink style={modalInputLabelTextStyle}>
                      Company
                    </InputLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <CustomInputWithFocus
                      placeholder="Enter company name..."
                      style={{ height: '4rem', width: '100%' }}
                      value={company}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setCompany(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{ marginTop: '30px' }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <InputLabel shrink style={modalInputLabelTextStyle}>
                      Email
                    </InputLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: '4rem',
                      width: '100%',
                      borderRadius: 2,
                      backgroundColor: '#FFFFFF',
                      padding: '10px 15px 10px 15px',
                      fontSize: '1.4rem',
                      lineHeight: '2.1rem',
                      fontFamily: 'Prompt',
                      color: '#292336',
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}
                  >
                    {state.loggedInUser.email}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            style={bottomButtonModalStyle}
          >
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: '0px 10px 0px 0px' }}
            >
              <Box style={{ width: '120px' }}>
                <CancelButton
                  textValue="CANCEL"
                  height="32px"
                  onclick={handleCancel}
                ></CancelButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
              alignItems="center"
              style={{ padding: '0px 0px 0px 10px' }}
            >
              <Box style={{ width: '120px' }}>
                <MainButton
                  textValue="SAVE"
                  height="32px"
                  onclick={handleSave}
                ></MainButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
