import {
  AppBar,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Box,
  ButtonBase,
  Hidden,
  IconButton
} from '@material-ui/core';
import React, { useContext } from 'react';
import { TopMenuGeneralActionPanel } from './TopMenuGeneralActionPanel';
import { TopMenuPanelProps, ViewMode } from '../../types/interfaces';
import { HorizontalDivider } from '../reusable/HorizontalDivider';
import { topMenuGeneralActionPanelNameStyle } from '../../styles/styles';
import { BackIcon, UserIcon } from '../reusable/IconImages';
import MenuIcon from '@material-ui/icons/Menu';
import { InviteUserButton } from '../project/InviteUserButton';
import { AppContext } from '../../context/context';
import { getProject } from '../../helper/helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarStyle: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100vw - 320px)`
      },
      [theme.breakpoints.down('sm')]: {
        width: `100vw`
      },
      backgroundColor: '#f0f0f0'
    },
    menuIconGridStyle: {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    menuIconStyle: {
      height: '40px',
      width: '40px',
      padding: '0px 0px 0px 20px',
      margin: '0px 0px 0px 0px',
      backgroundColor: '#F0f0f0',
      boxShadow: 'none',
      color: '#292336'
    }
  })
);

export default function TopMenuPanel(props: TopMenuPanelProps) {
  const { state } = useContext(AppContext);

  let selectedProject = getProject(state.projectList, props.selectedProjectId);

  const classes = useStyles();
  let header = null;
  if (props.viewMode === ViewMode.ProjectPage) {
    header = (
      <Grid container item xs={7} style={{ alignItems: 'center' }}>
        <Grid className={classes.menuIconGridStyle} item>
          <ButtonBase
            className={classes.menuIconStyle}
            onClick={props.onSideMenuIconClicked}
            disableRipple={true}
          >
            <MenuIcon />
          </ButtonBase>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Box
              fontWeight={700}
              alignItems={'center'}
              justifyContent="center"
              style={topMenuGeneralActionPanelNameStyle}
            >
              {selectedProject !== undefined
                ? selectedProject.name
                : 'No project selected'}
            </Box>
          </Grid>
        </Hidden>
        <Grid
          item
          style={{
            paddingLeft: '25px',
            paddingTop: '12px',
            paddingBottom: '14px'
          }}
        >
          <Grid container item>
            {selectedProject !== undefined
              ? selectedProject.sharedWith.slice(0, 3).map(sharedWith => (
                  <React.Fragment key={sharedWith.userId}>
                    <UserIcon
                      userId={sharedWith.userId}
                      firstNameOfUser={sharedWith.firstNameOfUser}
                      lastNameOfUser={sharedWith.lastNameOfUser}
                      imgPathOfUser={sharedWith.imgPathOfUser}
                      downloadedImage={sharedWith.downloadedImage}
                      emailOfUser={sharedWith.emailOfUser}
                      height={'30px'}
                      marginLeft={'-5px'}
                    />
                  </React.Fragment>
                ))
              : null}
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            paddingTop: '12px',
            paddingBottom: '14px',
            paddingLeft: '10px'
          }}
        >
          {selectedProject !== undefined ? (
            <InviteUserButton selectedProjectId={props.selectedProjectId} />
          ) : null}
        </Grid>
      </Grid>
    );
  } else if (props.viewMode === ViewMode.SimulationReview) {
    header = (
      <Grid
        container
        item
        xs={7}
        style={{
          alignItems: 'center',
          paddingLeft: '10px'
        }}
      >
        <Grid className={classes.menuIconGridStyle} item>
          <IconButton
            color="default"
            disableFocusRipple={true}
            style={{
              borderColor: 'lightgray',
              boxShadow: 'none',
              height: '30px',
              width: '30px',
              padding: '0px'
            }}
          >
            <BackIcon clicked={props.onSideMenuIconClicked} />
          </IconButton>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Box
              fontWeight={700}
              alignItems={'center'}
              justifyContent="center"
              style={topMenuGeneralActionPanelNameStyle}
            >
              {selectedProject !== undefined
                ? selectedProject.name
                : 'Project page example'}
            </Box>
          </Grid>
        </Hidden>
        <Grid
          item
          style={{
            paddingLeft: '25px',
            paddingTop: '12px',
            paddingBottom: '14px'
          }}
        >
          <Grid container item>
            {selectedProject !== undefined
              ? selectedProject.sharedWith.slice(0, 3).map(sharedWith => (
                  <React.Fragment key={sharedWith.userId}>
                    <UserIcon
                      userId={sharedWith.userId}
                      firstNameOfUser={sharedWith.firstNameOfUser}
                      lastNameOfUser={sharedWith.lastNameOfUser}
                      imgPathOfUser={sharedWith.imgPathOfUser}
                      downloadedImage={sharedWith.downloadedImage}
                      emailOfUser={sharedWith.emailOfUser}
                      height={'30px'}
                      marginLeft={'-5px'}
                    />
                  </React.Fragment>
                ))
              : null}
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            paddingTop: '12px',
            paddingBottom: '14px',
            paddingLeft: '10px'
          }}
        >
          {selectedProject !== undefined ? (
            <InviteUserButton selectedProjectId={props.selectedProjectId} />
          ) : null}
        </Grid>
      </Grid>
    );
  }

  return (
    <AppBar position="fixed" className={classes.appBarStyle} elevation={0}>
      <Grid container>
        {header}
        <Grid item xs={5}>
          <TopMenuGeneralActionPanel />
        </Grid>
        <Grid item xs={12}>
          <HorizontalDivider />
        </Grid>
      </Grid>
    </AppBar>
  );
}
