import searchIcon from '../../images/icons/search.png';
import commentIcon from '../../images/icons/comment.png';
import doubleCommentIcon from '../../images/icons/doublecomment.png';
import bellIcon from '../../images/icons/bell.png';
import lifeBeltIcon from '../../images/icons/lifebelt.png';
import moreIcon from '../../images/icons/more.png';
import plusIcon from '../../images/icons/plus.png';
import closeIcon from '../../images/icons/close.png';
import backIcon from '../../images/icons/back.png';
import closeWithBorderIcon from '../../images/icons/closeWithBorder.png';
import deleteIcon from '../../images/icons/delete.png';
import removeIcon from '../../images/icons/remove.png';
import ellipseIcon from '../../images/icons/ellipse.png';
import redEllipseIcon from '../../images/icons/redEllipse.png';
import chevronRightIcon from '../../images/icons/chevronRight.png';
import navLeftIcon from '../../images/icons/navLeft.png';
import navRightIcon from '../../images/icons/navRight.png';
import timeSymbolIcon from '../../images/icons/timeSymbolCircle.png';
import activeTimeSymbolIcon from '../../images/icons/activeTimeSymbol.png';
import replyArrowIcon from '../../images/icons/replyArrow.png';
import activeTimeSymbolHorizontalIcon from '../../images/icons/activeTimeSymbolHorizontal.png';
import commentSeparatorLineIcon from '../../images/icons/commentSeparatorLine.png';
import inactiveTimeSymbolHorizontalIcon from '../../images/icons/inactiveTimeSymbolHorizontal.png';
import grayLogoIcon from '../../images/icons/grayLogo.png';
import arrowIcon from '../../images/icons/arrow.png';
import checkMarkCircle from '../../images/icons/checkmarkCircle.svg';

import React from 'react';
import {
  DeleteIconProps,
  UserIconProps,
  CloseIconProps,
  SearchIconProps
} from '../../types/interfaces';
import {
  Box,
  Avatar,
  withStyles,
  createStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

export function SearchIcon(props: SearchIconProps) {
  return (
    <img
      style={{ marginTop: '7px' }}
      src={searchIcon}
      alt={'search'}
      height={props.height}
    />
  );
}

export function CommentIcon() {
  return <img src={commentIcon} alt={'comment'} />;
}

export function DoubleCommentIcon() {
  return <img src={doubleCommentIcon} alt={'double comment'} />;
}

export function BellIcon() {
  return <img src={bellIcon} alt={'bell'} />;
}

export function LifeBeltIcon() {
  return <img src={lifeBeltIcon} alt={'lifebelt'} />;
}

export function MoreIcon() {
  return <img src={moreIcon} alt={'more'} />;
}

export function CheckMarkCircle() {
  return <img src={checkMarkCircle} alt={'checkmark'} />;
}

export function PlusIcon() {
  return <img src={plusIcon} alt={'plus'} height="30px" />;
}

export function CloseIcon(props: CloseIconProps) {
  return <img onClick={() => props.clicked()} src={closeIcon} alt={'close'} />;
}

export function CloseWithBorderIcon(props: CloseIconProps) {
  return (
    <img
      onClick={() => props.clicked()}
      src={closeWithBorderIcon}
      alt={'close with border'}
    />
  );
}

export function DeleteIcon(props: DeleteIconProps) {
  return (
    <img
      onClick={() => props.clicked(props.value)}
      src={deleteIcon}
      alt={'delete'}
      height="20px"
      style={{
        cursor: 'pointer',
        height: 'auto',
        margin: '0 5px 0 -8px'
      }}
    />
  );
}

export function BackIcon(props: CloseIconProps) {
  return <img onClick={() => props.clicked()} src={backIcon} alt={'back'} />;
}

export function RemoveIcon(props: DeleteIconProps) {
  return (
    <img
      onClick={() => props.clicked(props.value)}
      src={removeIcon}
      alt={'remove'}
      height="20px"
    />
  );
}

const PersonAvatar = withStyles(() =>
  createStyles({
    root: {
      background: '#292336',
      fontFamily: 'Prompt',
      fontWeight: 'bold',
      fontSize: '1.4rem',
      color: '#FFFFFF'
    }
  })
)(Avatar);

export function UserIcon(props: UserIconProps) {
  let fontSizeRelatedToPersonAvatar = parseInt(props.height) * 0.4;
  let toRet = <React.Fragment />;
  if (
    props.imgPathOfUser.trim() !== '' &&
    props.downloadedImage !== undefined
  ) {
    toRet = (
      <PersonAvatar
        style={{
          height: props.height,
          width: props.height,
          marginLeft: props.marginLeft
        }}
      >
        <img
          src={'data:image/png;base64,' + props.downloadedImage}
          alt="sef"
          height={props.height}
        />
      </PersonAvatar>
    );
  } else if (
    props.firstNameOfUser.trim() !== '' ||
    props.lastNameOfUser.trim() !== ''
  ) {
    toRet = (
      <PersonAvatar
        style={{
          height: props.height,
          width: props.height,
          marginLeft: props.marginLeft
        }}
      >
        <Box style={{ fontSize: fontSizeRelatedToPersonAvatar }}>
          {props.firstNameOfUser.substring(0, 1).toUpperCase() +
            props.lastNameOfUser.substring(0, 1).toUpperCase()}
        </Box>
      </PersonAvatar>
    );
  } else {
    <PersonAvatar
      style={{
        height: props.height,
        width: props.height
      }}
    >
      <Box style={{ fontSize: fontSizeRelatedToPersonAvatar }}>
        {props.emailOfUser.substring(0, 2).toUpperCase()}
      </Box>
    </PersonAvatar>;
  }

  return toRet;
}

export function EllipseIcon() {
  const theme = useTheme();
  const visible = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      style={{
        position: 'relative',
        visibility: visible ? 'visible' : 'collapse'
      }}
    >
      <Box
        style={{
          position: 'absolute',
          right: '-3px',
          bottom: '-4.2px'
        }}
      >
        <img height="5px" width="5px" src={ellipseIcon} alt={'ellipse'} />
      </Box>
    </Box>
  );
}

export function RedEllipseIcon() {
  return (
    <img height="5px" width="5px" src={redEllipseIcon} alt={'red ellipse'} />
  );
}

export function ChevronRightIcon() {
  return (
    <img
      height="32px"
      width="32px"
      src={chevronRightIcon}
      alt={'chevron right'}
    />
  );
}

export function NavLeftIcon() {
  return <img height="40x" width="40px" src={navLeftIcon} alt={'nav left'} />;
}

export function NavRightIcon() {
  return (
    <img height="40px" width="40px" src={navRightIcon} alt={'nav right'} />
  );
}

export function TimeSymbolIcon() {
  return (
    <img height="9px" width="9px" src={timeSymbolIcon} alt="time symbol" />
  );
}

export function ActiveTimeSymbolIcon() {
  return (
    <img
      height="31px"
      width="19px"
      src={activeTimeSymbolIcon}
      alt="time symbol"
    />
  );
}

export function ActiveTimeSymbolHorizontalIcon() {
  return (
    <img
      height="15px"
      width="21px"
      src={activeTimeSymbolHorizontalIcon}
      alt="time symbol horizontal"
    />
  );
}

export function InactiveTimeSymbolHorizontalIcon() {
  return (
    <img
      height="15px"
      width="21px"
      src={inactiveTimeSymbolHorizontalIcon}
      alt="inactive time symbol horizontal"
    />
  );
}

export function ReplyArrowIcon() {
  return (
    <img height="19px" width="13.5px" src={replyArrowIcon} alt="reply arrow" />
  );
}

export function CommentSeparatorLineIcon() {
  return (
    <img
      height="1px"
      width="10px"
      src={commentSeparatorLineIcon}
      alt="comment separator line"
    />
  );
}

export function GrayLogoIcon() {
  return (
    <img height="100px" width="110px" src={grayLogoIcon} alt="gray logo" />
  );
}

export function ArrowIcon() {
  return <img height="9px" width="12px" src={arrowIcon} alt="arrow logo" />;
}
