import React, { useContext, useState } from 'react';
import {
  sideMenuStyle,
  sideMenuNewProjectButtonPadding,
  sideMenuProjectListStyle,
  sideMenuSettingsStyle,
  sideMenuSettingsButtonSpacer2Style,
  sideMenuSettingsLinkSpacerStyle,
  sideMenuSettingsLinkStyle
} from '../../styles/styles';
import { ImageList } from '@material-ui/core';
import { Box, Link, Grid } from '@material-ui/core';
import { SideMenuPanelProps } from '../../types/interfaces';
import { SideMenuItem } from './SideMenuItem';
import { MainButton, DefaultButton } from '../reusable/Buttons';
import { HorizontalDivider } from '../reusable/HorizontalDivider';
import { NewProjectModal } from '../project/NewProjectModal';
import { EllipseIcon } from '../reusable/IconImages';
import { AppContext } from '../../context/context';

export function SideMenuPanel(props: SideMenuPanelProps) {
  const { state } = useContext(AppContext);
  const [newProjectPopupOpen, setNewProjectPopupOpen] = useState(false);

  return (
    <Grid style={sideMenuStyle}>
      <HorizontalDivider />
      <EllipseIcon />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={sideMenuNewProjectButtonPadding}
      >
        <Box style={{ width: '280px', marginBottom: '-14px' }}>
          <MainButton
            textValue="NEW PROJECT"
            height="4rem"
            onclick={() => setNewProjectPopupOpen(true)}
          />
        </Box>
        <NewProjectModal
          isOpen={newProjectPopupOpen}
          handleClose={() => setNewProjectPopupOpen(false)}
        />
      </Grid>
      <Box style={sideMenuProjectListStyle}>
        <ImageList
          rowHeight={40}
          cols={1}
          style={{ display: 'block', overflowY: 'visible' }}
        >
          {state.projectList
            // if only active projects arrive from the api, filtering is not needed
            .filter(project => !project.archived)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(project => (
              <SideMenuItem
                key={project.id}
                projectId={project.id}
                selectedProjectId={props.selectedProjectId}
                onProjectSelectionChanged={props.onProjectSelectionChanged}
                onViewModeChange={props.onViewModeChange}
              />
            ))}
        </ImageList>
      </Box>
      <Box style={sideMenuSettingsStyle}>
        <HorizontalDivider />
        <EllipseIcon />
        <Box style={sideMenuSettingsButtonSpacer2Style}>
          <DefaultButton textValue="CUSTOMER SUPPORT" height="2.2rem" />
        </Box>
        <Grid
          container
          justifyContent="space-around"
          style={sideMenuSettingsLinkSpacerStyle}
        >
          <Grid item xs={1}></Grid>
          <Grid item>
            <Link style={sideMenuSettingsLinkStyle} underline="none">
              Terms & Condition
            </Link>
          </Grid>
          <Grid item>
            <Link style={sideMenuSettingsLinkStyle} underline="none">
              Contact
            </Link>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
