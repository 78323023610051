import React from 'react';
import { LogoData } from '../../types/interfaces';
import { logoPanelStyle } from '../../styles/styles';
import { Box } from '@material-ui/core';

export function LogoPanel(logoData: LogoData) {
  return (
    <Box style={logoPanelStyle}>
      <img src={logoData.logoPath} alt={logoData.altText} />
    </Box>
  );
}
