import React from "react";
import { Divider } from "@material-ui/core";

const horizontalDividerStyle = {
  backgroundColor: "#A8A8AE"
};

const redHorizontalDividerStyle = {
  backgroundColor: "#E73636"
};

const whiteHorizontalDividerStyle = {
  backgroundColor: "#FFFFFF"
};

export function HorizontalDivider() {
  return <Divider style={horizontalDividerStyle} />;
}

export function RedHorizontalLine() {
  return <Divider style={redHorizontalDividerStyle} />;
}

export function WhiteHorizontalLine() {
  return <Divider style={whiteHorizontalDividerStyle} />;
}
