import {
  Modal,
  Box,
  Grid,
  InputLabel,
  IconButton,
  Hidden
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { GetProject, UpdateSimulation } from '../../helper/apiHelper';
import { AppContext } from '../../context/context';
import { Types } from '../../reducers/reducers';
import {
  bottomButtonModalStyle,
  displayBlock,
  fullScreenModalBackContainerStyle,
  fullScreenModalBackgroundStyle,
  modalCloseButtonBoxStyle,
  modalCloseButtonStyle,
  modalCloseTextStyle,
  modalInputLabelTextStyle,
  modalTitleStyleSmallerTopPadding,
  whiteBackgroundInputWindow
} from '../../styles/styles';
import { SimulationEditModalProps } from '../../types/interfaces';
import { CancelButton, MainButton } from '../reusable/Buttons';
import { CustomInputWithFocus } from '../reusable/CustomInputs';
import { CloseIcon } from '../reusable/IconImages';
import {
  RefreshSimulationCoverImage,
  RefreshSharedWithUserImage
} from '../../helper/imageHelper';
import { StyledMenuBottomRight } from '../reusable/StyledMenu';

export const SimulationEditModal = (props: SimulationEditModalProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [description, setDescription] = useState(props.simulation.description);
  const [name, setName] = useState(props.simulation.name);

  async function handleSave() {
    if (name.length > 0) {
      await UpdateSimulation(props.simulation.id, name, description, dispatch);
      let project = await GetProject(props.projectId, dispatch);
      dispatch({
        type: Types.UpdateProject,
        payload: {
          project: project
        }
      });
      RefreshSimulationCoverImage(project, dispatch, state.downloadedImages);
      RefreshSharedWithUserImage(project, dispatch, state.downloadedImages);
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Simulation edited successfully.',
            severity: 'success'
          }
        }
      });
      props.handleClose();
    } else {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Name and description are mandatory.',
            severity: 'error'
          }
        }
      });
    }
  }

  function handleCancel() {
    setDescription(props.simulation.description);
    setName(props.simulation.name);
    props.handleClose();
  }

  return (
    <Modal open={props.isOpen}>
      <Box style={fullScreenModalBackgroundStyle}>
        <Hidden mdDown>
          <Box style={modalCloseButtonBoxStyle}>
            <Grid container justifyContent="center" style={displayBlock}>
              <Grid item xs={12}>
                <IconButton
                  style={modalCloseButtonStyle}
                  color="default"
                  disableFocusRipple={true}
                >
                  <CloseIcon clicked={handleCancel} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box style={modalCloseTextStyle}>CLOSE</Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={fullScreenModalBackContainerStyle}
        >
          <Grid
            item
            xs={12}
            container
            style={{ maxWidth: '100%' }}
            justifyContent="center"
          >
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={whiteBackgroundInputWindow}
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: '30px',
                  marginLeft: '10%',
                  marginRight: '10%'
                }}
              >
                <Grid item xs={12} container justifyContent="center">
                  <Box
                    style={modalTitleStyleSmallerTopPadding}
                    fontWeight={700}
                  >
                    Edit simulation
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Name
                  </InputLabel>
                  <CustomInputWithFocus
                    required
                    placeholder="Simulation name..."
                    style={{
                      height: '4rem',
                      marginBottom: '40px'
                    }}
                    value={name}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setName(e.target.value)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Description
                  </InputLabel>
                  <CustomInputWithFocus
                    placeholder="Description..."
                    minRows={2}
                    maxRows={2}
                    multiline={true}
                    style={{ marginBottom: '40px', marginTop: '-3px' }}
                    value={description}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setDescription(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            style={bottomButtonModalStyle}
          >
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: '0px 10px 0px 0px' }}
            >
              <Box style={{ width: '120px' }}>
                <CancelButton
                  textValue="CANCEL"
                  height="32px"
                  onclick={handleCancel}
                ></CancelButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
              alignItems="center"
              style={{ padding: '0px 0px 0px 10px' }}
            >
              <Box
                style={{
                  width: '120px'
                }}
              >
                <MainButton
                  textValue="SAVE"
                  height="32px"
                  onclick={handleSave}
                ></MainButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
