import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { CustomInput } from '../reusable/CustomInputs';
import { CancelButton, SecondaryButton } from '../reusable/Buttons';
import { AddNewCommentItemProps } from '../../types/interfaces';

export function AddNewCommentItem(props: AddNewCommentItemProps) {
  return (
    <Grid container>
      <CustomInput
        placeholder={'Start typing...'}
        minRows={3}
        maxRows={3}
        multiline={true}
        style={{ width: '100%' }}
        value={props.commentText}
        onChange={(e: { target: { value: string } }) =>
          props.onInputChanged(e.target.value)
        }
      />
      <Grid container justifyContent="flex-end">
        <Box style={{ width: '120px', paddingRight: '10px' }}>
          <CancelButton
            textValue="CANCEL"
            height="2.2rem"
            onclick={() => props.onCancelPressed()}
          />
        </Box>
        <Box style={{ width: '120px' }}>
          <SecondaryButton
            textValue="COMMENT"
            height="2.2rem"
            onclick={props.onCommentAdded}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
