import React from 'react';
import { Menu, makeStyles } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';

const useStyles = makeStyles(() => ({
  menuBorder: {
    border: '1px solid #A8A6AE',
    borderRadius: '8px'
  }
}));

export const StyledMenuBottomRight = (props: MenuProps) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ paper: classes.menuBorder }}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    />
  );
};

export const StyledMenuTopRight = (props: MenuProps) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ paper: classes.menuBorder }}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      {...props}
    />
  );
};
