import React, { useContext, useState } from 'react';
import { DataViewPanelProps, ViewMode } from '../../types/interfaces';
import { Grid, Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import { SimulationItem } from '../simulation/SimulationItem';
import { DataViewActionPanel } from './DataViewActionPanel';
import { GrayLogoIcon } from '../reusable/IconImages';
import { MainButton } from '../reusable/Buttons';
import { NewProjectModal } from '../project/NewProjectModal';
import { NewSimulationModal } from '../simulation/NewSimulationModal';
import { AppContext } from '../../context/context';
import { getFilteredSimulations, getProject } from '../../helper/helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataViewPanelStyle: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100vw - 320px)`
      },
      [theme.breakpoints.down('sm')]: {
        width: `100vw`
      },
      marginTop: '67px'
    },
    dataViewPanelPadding: {
      padding: '15px 15px 15px 15px'
    }
  })
);

export function DataViewPanel(props: DataViewPanelProps) {
  const { state } = useContext(AppContext);

  let selectedProject = getProject(state.projectList, props.selectedProjectId);
  const classes = useStyles();
  const [projectPopupOpen, setProjectPopupOpen] = useState(false);
  const [simulationPopupOpen, setSimulationPopupOpen] = useState(false);

  let data = null;
  if (props.viewMode === ViewMode.ProjectPage) {
    if (selectedProject !== undefined) {
      if (selectedProject.simulations.length > 0) {
        data = (
          <Grid
            container
            className={classes.dataViewPanelPadding}
            style={{ display: 'flex' }}
          >
            {getFilteredSimulations(
              props.searchText,
              selectedProject.simulations
            ).map(simulation => (
              <Grid
                key={simulation.id}
                container
                item
                xl={3}
                md={4}
                sm={6}
                xs={12}
                style={{
                  minWidth: '300px',
                  justifyContent: 'center',
                  flexGrow: 1,
                  padding: '1rem'
                }}
              >
                <SimulationItem
                  projectId={props.selectedProjectId}
                  simulation={simulation}
                  onViewModeChangeToSimulationReview={
                    props.onViewModeChangeToSimulationReview
                  }
                />
              </Grid>
            ))}
          </Grid>
        );
      } else {
        data = (
          <Grid container className={classes.dataViewPanelPadding}>
            <Grid container>
              <Grid
                container
                justifyContent="center"
                style={{ paddingTop: '150px' }}
              >
                <GrayLogoIcon />
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{
                  fontFamily: 'Prompt',
                  fontSize: '1.8rem',
                  lineHeight: '2.7rem',
                  color: '#292336',
                  fontWeight: 'bold',
                  paddingTop: '25px'
                }}
              >
                No simulations yet
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{
                  fontFamily: 'Prompt',
                  fontSize: '1.4rem',
                  lineHeight: '1.7rem',
                  color: '#292336',
                  paddingTop: '10px'
                }}
              >
                Feel free to add one, just click the button below
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{ paddingTop: '25px' }}
              >
                <Box style={{ width: '150px' }}>
                  <MainButton
                    textValue="NEW SIMULATION"
                    height="3.2rem"
                    onclick={() => setSimulationPopupOpen(true)}
                  />
                </Box>
                <NewSimulationModal
                  selectedProjectId={props.selectedProjectId}
                  isOpen={simulationPopupOpen}
                  handleClose={() => setSimulationPopupOpen(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      }
    } else {
      data = (
        <Grid container className={classes.dataViewPanelPadding}>
          <Grid container>
            <Grid
              container
              justifyContent="center"
              style={{ paddingTop: '150px' }}
            >
              <GrayLogoIcon />
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{
                fontFamily: 'Prompt',
                fontSize: '1.8rem',
                lineHeight: '2.7rem',
                color: '#292336',
                fontWeight: 'bold',
                paddingTop: '25px'
              }}
            >
              {state.projectList.length > 0
                ? 'No project selected'
                : 'No projects yet'}
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{
                fontFamily: 'Prompt',
                fontSize: '1.4rem',
                lineHeight: '1.7rem',
                color: '#292336',
                paddingTop: '10px'
              }}
            >
              Feel free to add one, just click the button below
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ paddingTop: '25px' }}
            >
              <Box style={{ width: '150px' }}>
                <MainButton
                  textValue="NEW PROJECT"
                  height="3.2rem"
                  onclick={() => setProjectPopupOpen(true)}
                />
              </Box>
              <NewProjectModal
                isOpen={projectPopupOpen}
                handleClose={() => setProjectPopupOpen(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Box className={classes.dataViewPanelStyle}>
      <Grid container item xs={12}>
        <DataViewActionPanel
          searchText={props.searchText}
          viewMode={props.viewMode}
          onSearchTextChanged={props.onSearchTextChanged}
          selectedProjectId={props.selectedProjectId}
        />
      </Grid>
      {data}
    </Box>
  );
}
