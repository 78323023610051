import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  topMenuPadding,
  topMenuButtonWithNoPadding,
  autoWidth,
  accountText
} from '../../styles/styles';
import { UserIcon } from '../reusable/IconImages';
import downArrow from '../../images/icons/downArrow.svg';
import blackBell from '../../images/icons/blackBell.svg';
import IconButton from '@material-ui/core/IconButton';
import { AccountMenuDialog } from './AccountMenuDialog';
import { AccountSettingsModal } from './AccountSettingsModal';
import { createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import { AppContext } from '../../context/context';
import { authProvider } from '../../authProvider';
import { stat } from 'fs';

export function TopMenuGeneralActionPanel() {
  const { state } = useContext(AppContext);
  const [menuPopupOpen, setMenuPopupOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(false);

  const handleClose = () => {
    setMenuPopupOpen(false);
  };

  const handleMenuPopupOpen = (target: any) => {
    setMenuPopupOpen(true);
    setMenuAnchor(target);
  };

  const handleAccountSettingsOpen = () => {
    handleClose();
    setAccountSettingsOpen(true);
  };

  const handleAccountSettingsClose = () => {
    setAccountSettingsOpen(false);
  };

  const handleLogout = () => {
    authProvider.logout();
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      imageStyle: {
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          flexDirection: 'row-reverse'
        },
        [theme.breakpoints.up('sm')]: {}
      }
    })
  );
  const classes = useStyles();

  return (
    <Grid
      style={topMenuPadding}
      container
      justifyContent="flex-end"
      item
      xs={12}
    >
      <Grid style={{ ...autoWidth, display: 'flex' }}>
        <div style={{ ...accountText, color: '#7F7986' }}>12</div>
        <img
          alt="blackBell"
          src={blackBell}
          style={{ marginLeft: '6px', marginTop: '-4px' }}
        />
        <Hidden xsDown>
          <div
            style={accountText}
            onClick={event => {
              let target = event.currentTarget;
              handleMenuPopupOpen(target);
            }}
          >
            {state.loggedInUser.firstName + ' ' + state.loggedInUser.lastName}
          </div>
        </Hidden>
        <div className={classes.imageStyle}>
          <img
            alt="downArrow"
            src={downArrow}
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={event => {
              let target = event.currentTarget;
              handleMenuPopupOpen(target);
            }}
          />
          <IconButton
            style={topMenuButtonWithNoPadding}
            color="default"
            disableRipple={true}
          >
            <UserIcon
              userId={state.loggedInUser.id}
              firstNameOfUser={state.loggedInUser.firstName}
              lastNameOfUser={state.loggedInUser.lastName}
              imgPathOfUser={state.loggedInUser.imgPath}
              downloadedImage={state.loggedInUser.downloadedImage}
              emailOfUser={state.loggedInUser.email}
              height={'37px'}
              marginLeft={'0px'}
            />
          </IconButton>
        </div>
        <AccountSettingsModal
          isOpen={accountSettingsOpen}
          handleClose={handleAccountSettingsClose}
        />
        <AccountMenuDialog
          isOpen={menuPopupOpen}
          menuAnchor={menuAnchor}
          handleClose={handleClose}
          handleLogout={handleLogout}
          handleAccountSettingsOpen={handleAccountSettingsOpen}
        />
      </Grid>
    </Grid>
  );
}
