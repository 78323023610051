import React from 'react';
import { Grid } from '@material-ui/core';
import { AccountMenuDialogProps } from '../../types/interfaces';
import { LightMenuButtonHover } from '../reusable/Buttons';
import { StyledMenuBottomRight } from '../reusable/StyledMenu';

export function AccountMenuDialog(
  accountMenuDialogProps: AccountMenuDialogProps
) {
  return (
    <StyledMenuBottomRight
      keepMounted
      open={accountMenuDialogProps.isOpen}
      onClose={accountMenuDialogProps.handleClose}
      anchorEl={accountMenuDialogProps.menuAnchor}
    >
      <Grid
        style={{ width: '230px', padding: '0px 10px' }}
        xs={12}
        container
        item
      >
        <Grid xs={12} item style={{ padding: '5px 0px' }}>
          <LightMenuButtonHover
            onclick={accountMenuDialogProps.handleAccountSettingsOpen}
            textValue="Account settings"
            height="3.3rem"
          />
        </Grid>
        <Grid xs={12} item style={{ padding: '5px 0px' }}>
          <LightMenuButtonHover
            onclick={accountMenuDialogProps.handleLogout}
            textValue="Log out"
            height="3.3rem"
          />
        </Grid>
      </Grid>
    </StyledMenuBottomRight>
  );
}
