import { Configuration } from 'msal';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
const tenant = 'arbiraadb2c.onmicrosoft.com';
const signInPolicy = 'B2C_1_ArbiraWeb_SignInSignUp';
const applicationID = '36923e90-fae8-4794-8e98-28bf54fb3481';
const reactRedirectUri = 'https://pms.arbira.com/';
const tenantSubdomain = tenant.split('.')[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
// Msal Configurations
const signInConfig: Configuration = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: reactRedirectUri,
    validateAuthority: false
  },
  cache: {
    cacheLocation: 'sessionStorage'
  }
};
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'https://arbiraadb2c.onmicrosoft.com/36923e90-fae8-4794-8e98-28bf54fb3481/Files.Read'
  ]
};
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
};
export const authProvider = new MsalAuthProvider(
  signInConfig,
  authenticationParameters,
  options
);
