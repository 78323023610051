import { Color } from '@material-ui/lab/Alert';
import { MouseEventHandler, ChangeEventHandler } from 'react';

// ### App
export interface AppProps {
  projectList: Project[];
  selectedSimulation: Simulation;
  loggedInUser: User;
  snackbarData: SnackbarData;
  downloadedImages: { [key: string]: string };
}

export interface SnackbarData {
  open: boolean;
  message: string;
  severity: Color;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  creationTime: string;
  archived: boolean;
  simulations: SimulationBase[];
  sharedWith: SharedWith[];
}

export interface SimulationBase {
  id: string;
  name: string;
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  creationTime: string;
  description: string;
  screenCapture: string;
  downloadedImage: string;
  snapshotLoaded: boolean;
  totalFeedbackCount: number;
  lengthInMilliSeconds: number;
}

export interface SharedWith {
  id: string;
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  imgPathOfUser: string;
  downloadedImage: string;
  emailOfUser: string;
  sharedDate: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  company: string;
  imgPath: string;
  downloadedImage: string;
  email: string;
}

export interface Simulation {
  id: string;
  name: string;
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  creationTime: string;
  description: string;
  screenCapture: string;
  totalFeedbackCount: number;
  projectId: string;
  projectName: string;
  robotId: string;
  modelOfRobot: string;
  manufacturerOfRobot: string;
  lengthInMilliSeconds: number;
  simulationFile: string;
  feedbacks: Feedback[];
}

export interface Feedback {
  id: string;
  imagePath: string;
  downloadedImage: string;
  positionInMilliseconds: number;
  comments: Comment[];
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  imgPathOfUser: string;
  creationTime: string;
}

export interface Comment {
  id: string;
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  imgPathOfUser: string;
  downloadedImage: string;
  creationTime: string;
  text: string;
  comments: Comment[];
}

export enum ViewMode {
  ProjectPage,
  SimulationReview
}

export enum UploadViewMode {
  Upload,
  UploadSuccess
}

// ### Side menu
export interface SideMenuDrawerPanelProps {
  selectedProjectId: string;
  sideMenuOpen: boolean;
  onProjectSelectionChanged(newSelection: string): void;
  onSideMenuOpenCloseAction(): void;
  onViewModeChange(newViewMode: ViewMode): void;
}
export interface SideMenuItemProps {
  selectedProjectId: string;
  projectId: string;
  onProjectSelectionChanged(newSelection: string): void;
  onViewModeChange(newViewMode: ViewMode): void;
}
export interface SideMenuPanelProps {
  selectedProjectId: string;
  onProjectSelectionChanged(newSelection: string): void;
  onViewModeChange(newViewMode: ViewMode): void;
}
export interface SideMenuItemMenuDialogProps {
  isOpen: boolean;
  menuAnchor: HTMLElement | null;
  handleClose(): void;
  handleOpenProject(): void;
  handleEditProject(): void;
  handleShareProject(): void;
  handleArchiveProject(): void;
}
export interface AccountMenuDialogProps {
  isOpen: boolean;
  menuAnchor: HTMLElement | null;
  handleClose(): void;
  handleAccountSettingsOpen(): void;
  handleLogout(): void;
}

export interface SimulationSettingsMenuDialogProps {
  isOpen: boolean;
  menuAnchor: HTMLElement | null;
  handleClose(): void;
  handleSimulationUpdatePressed(): void;
}

export interface AccountSettingsModalProps {
  isOpen: boolean;
  handleClose(): void;
}

export interface SimulationEditModalProps {
  isOpen: boolean;
  handleClose(): void;
  projectId: string;
  simulation: SimulationBase;
}

// ### Project edit modal
export interface ProjectEditModalProps {
  projectId: string;
  isOpen: boolean;
  handleClose(): void;
}

// ### Shared with item
export interface SharedWithItemProps {
  sharedWith: SharedWith;
}

// ### Logo panel
export interface LogoData {
  logoPath: string;
  altText: string;
}

// ### Search panel
export interface SearchPanelProps {
  searchText: string;
  placeholder: string;
  onSearchTextChanged(newSearchText: string): void;
}

// ### Top menu
export interface TopMenuPanelProps {
  selectedProjectId: string;
  viewMode: ViewMode;
  onSideMenuIconClicked(): void;
}

// ### Invite user
export interface InviteUserButtonProps {
  selectedProjectId: string;
}

export interface InviteUserModalProps {
  projectId: string;
  sharedWith: SharedWith[];
  isOpen: boolean;
  handleClose(): void;
}

export interface NewProjectModalProps {
  isOpen: boolean;
  handleClose(): void;
}

// ### Data view panel
export interface DataViewPanelProps {
  searchText: string;
  selectedProjectId: string;
  viewMode: ViewMode;
  onSearchTextChanged(newSearchText: string): void;
  onViewModeChangeToSimulationReview(
    newViewMode: ViewMode,
    simulationId: string
  ): void;
}
export interface DataViewActionPanelProps {
  searchText: string;
  viewMode: ViewMode;
  selectedProjectId: string;
  onSearchTextChanged(newSearchText: string): void;
}

// ### Simulation item
export interface SimulationItemProps {
  projectId: string;
  simulation: SimulationBase;
  onViewModeChangeToSimulationReview(
    newViewMode: ViewMode,
    simulationId: string
  ): void;
}
export interface NewSimulationModalProps {
  selectedProjectId: string;
  isOpen: boolean;
  handleClose(): void;
}

export interface SimulationReviewProps {
  selectedProjectId: string;
  onSimulationReviewBackButtonClicked(): void;
}
export interface FeedbackItemProps {
  feedback: Feedback;
  selectedfeedbackId: string;
  onJumpToFeedback(): void;
  onAddCommentForFeedback(id: string, text: string): void;
  projectId: string;
  simulationId: string;
}
export interface CommentPanelProps {
  comment: Comment;
  projectId: string;
  simulationId: string;
  feedbackId: string;
  parentCommentId: string;
}
export interface AddNewCommentItemProps {
  commentText: string;
  onInputChanged(value: string): void;
  onCancelPressed(): void;
  onCommentAdded(): void;
}

export enum UploadViewState {
  Initial,
  Uploading,
  Finished
}

export interface ModalUploadGridProps {
  uploadProcessValue: number;
  uploadViewState: UploadViewState;
  fileName: string;
  uploadText: string;
  supportedFormats: string;
  onChange?: ChangeEventHandler;
  onFileUploadCanceled(): void;
}
export interface MoadlUploadGridSupportedFormats {
  formats: string;
}

// ### Buttons
export interface ButtonProps {
  textValue: string;
  height: string;
  disabled?: boolean;
  onclick?: MouseEventHandler;
}
export interface UploadButtonProps {
  textValue: string;
  height: string;
  onChange?: ChangeEventHandler;
}

// ### Icons
export interface UserIconProps {
  userId: string;
  firstNameOfUser: string;
  lastNameOfUser: string;
  imgPathOfUser: string;
  downloadedImage: string;
  emailOfUser: string;
  height: string;
  marginLeft: string;
}
export interface DeleteIconProps {
  value: string;
  clicked(value: string): void;
}
export interface CloseIconProps {
  clicked(): void;
}
export interface SearchIconProps {
  height: string;
}

export enum FileType {
  UserImage = 0,
  Simulation = 1,
  Snapshot = 2
}
