import { ArbiraActions, Types } from '../reducers/reducers';
import { Feedback, FileType, Project, Simulation } from '../types/interfaces';
import { DownloadFile } from './apiHelper';

export function RefreshSimulationCoverImage(
  project: Project,
  dispatch: React.Dispatch<ArbiraActions>,
  state: { [key: string]: string }
) {
  project.simulations.forEach(async simulation => {
    if (!simulation.snapshotLoaded && simulation.screenCapture !== '') {
      let base64Img = '';
      if (simulation.id in state) base64Img = state[simulation.id];
      else {
        base64Img = await DownloadFile(
          simulation.screenCapture,
          FileType.Snapshot,
          dispatch
        );
        dispatch({
          type: Types.StoreImage,
          payload: {
            imageId: simulation.id,
            base64Image: base64Img
          }
        });
      }
      dispatch({
        type: Types.UpdateSnapshot,
        payload: {
          projectId: project.id,
          simulationId: simulation.id,
          base64Image: base64Img
        }
      });
    }
  });
}

export function RefreshSharedWithUserImage(
  project: Project,
  dispatch: React.Dispatch<ArbiraActions>,
  state: { [key: string]: string }
) {
  project.sharedWith.forEach(async user => {
    if (user.imgPathOfUser !== '' && user.downloadedImage === undefined) {
      let base64Img = '';
      if (user.userId in state) base64Img = state[user.userId];
      else {
        base64Img = await DownloadFile(
          user.imgPathOfUser,
          FileType.UserImage,
          dispatch
        );
        dispatch({
          type: Types.StoreImage,
          payload: {
            imageId: user.userId,
            base64Image: base64Img
          }
        });
      }
      dispatch({
        type: Types.SetSharedUserImage,
        payload: {
          projectId: project.id,
          userId: user.id,
          base64ImgString: base64Img
        }
      });
    }
  });
}

export function RefreshCommenterUserIcon(
  selectedSimulation: Simulation,
  dispatch: React.Dispatch<ArbiraActions>,
  state: { [key: string]: string }
) {
  selectedSimulation.feedbacks.forEach((feedback: Feedback) => {
    feedback.comments.forEach(async comment => {
      if (comment.imgPathOfUser !== '') {
        let base64Img = '';
        if (comment.userId in state) base64Img = state[comment.userId];
        else {
          base64Img = await DownloadFile(
            comment.imgPathOfUser,
            FileType.UserImage,
            dispatch
          );
          dispatch({
            type: Types.StoreImage,
            payload: {
              imageId: comment.userId,
              base64Image: base64Img
            }
          });
        }
        dispatch({
          type: Types.SetCommenterImage,
          payload: {
            feedbackId: feedback.id,
            commentId: comment.id,
            innerCommentId: '',
            base64ImgString: base64Img
          }
        });
        comment.comments.forEach(async innerComment => {
          if (innerComment.imgPathOfUser !== '') {
            let base64Img = '';
            if (innerComment.userId in state)
              base64Img = state[innerComment.userId];
            else {
              base64Img = await DownloadFile(
                innerComment.imgPathOfUser,
                FileType.UserImage,
                dispatch
              );
              dispatch({
                type: Types.StoreImage,
                payload: {
                  imageId: innerComment.userId,
                  base64Image: base64Img
                }
              });
            }
            dispatch({
              type: Types.SetCommenterImage,
              payload: {
                feedbackId: feedback.id,
                commentId: comment.id,
                innerCommentId: innerComment.id,
                base64ImgString: base64Img
              }
            });
          }
        });
      }
    });
  });
}

export async function SetFeedbackImage(
  feedback: Feedback | undefined,
  dispatch: React.Dispatch<ArbiraActions>,
  state: { [key: string]: string }
) {
  if (
    feedback !== undefined &&
    feedback.imagePath !== undefined &&
    feedback.imagePath !== ''
  ) {
    let base64Image = '';
    if (feedback.id in state) base64Image = state[feedback.id];
    else if (
      feedback.downloadedImage === undefined ||
      feedback.downloadedImage === ''
    ) {
      base64Image = await DownloadFile(
        feedback.imagePath,
        FileType.Snapshot,
        dispatch
      );
      dispatch({
        type: Types.StoreImage,
        payload: {
          imageId: feedback.id,
          base64Image: base64Image
        }
      });
    }
    dispatch({
      type: Types.UpdateFeedbackImage,
      payload: {
        feedbackId: feedback.id,
        base64Image: base64Image
      }
    });
  }
}
