import { Grid } from '@material-ui/core';
import React from 'react';
import { GrayLogoIcon } from '../reusable/IconImages';

export function Redirect() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ width: '100vw', height: '100vh' }}
    >
      <Grid>
        <GrayLogoIcon />
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{
          fontFamily: 'Prompt',
          fontSize: '1.8rem',
          lineHeight: '2.7rem',
          color: '#292336',
          fontWeight: 'bold',
          paddingTop: '25px'
        }}
      >
        Redirecting...
      </Grid>
    </Grid>
  );
}
