import React, { useContext, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { NewProjectModalProps } from '../../types/interfaces';
import {
  Box,
  Grid,
  InputLabel,
  IconButton,
  Hidden,
  ClickAwayListener
} from '@material-ui/core';
import { DeleteIcon, CloseIcon } from '../reusable/IconImages';
import { MainButton, CancelButton } from '../reusable/Buttons';
import {
  fullScreenModalBackgroundStyle,
  displayBlock,
  modalCloseButtonBoxStyle,
  modalCloseButtonStyle,
  modalCloseTextStyle,
  fullScreenModalBackContainerStyle,
  modalInputLabelTextStyle,
  whiteBackgroundInputWindow,
  modalTitleStyleSmallerTopPadding,
  bottomButtonModalStyle
} from '../../styles/styles';
import {
  CustomChipInput,
  CustomChip,
  CustomInputWithFocus
} from '../reusable/CustomInputs';
import { AppContext } from '../../context/context';
import { Types } from '../../reducers/reducers';
import { AddProject, GetProject, ShareProject } from '../../helper/apiHelper';

export function NewProjectModal(props: NewProjectModalProps) {
  const { state, dispatch } = useContext(AppContext);
  const [projectName, setProjectName] = useState('');
  const [newContacts, setNewContacts] = useState<string[]>([]);
  const [messageText, setMessageText] = useState('');
  const [description, setDescription] = useState('');
  const [borderColor, setBorderColor] = useState('1px solid #A8A6AE');

  function handleAddContact(contact: string) {
    setNewContacts(newContacts.concat(contact));
  }

  function handleDeleteContact(contactToRemove: string) {
    setNewContacts(newContacts.filter(contact => contact !== contactToRemove));
  }

  async function handleSave() {
    if (projectName.length > 0) {
      let newProjectId = await AddProject(
        projectName,
        description,
        state.loggedInUser,
        dispatch
      );
      await ShareProject(newProjectId, newContacts, messageText, dispatch);
      dispatch({
        type: Types.AddProject,
        payload: {
          project: await GetProject(newProjectId, dispatch)
        }
      });
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Project created successfully.',
            severity: 'success'
          }
        }
      });
      cleanInputFields();

      props.handleClose();
    } else {
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message: 'Project name is mandatory.',
            severity: 'error'
          }
        }
      });
    }
  }

  function cleanInputFields() {
    setProjectName('');
    setNewContacts([]);
    setMessageText('');
    setDescription('');
  }

  function handleMessageTextChange(value: string) {
    setMessageText(value);
  }

  function handleProjectNameChange(value: string) {
    setProjectName(value);
  }

  function handleDescriptionChange(value: string) {
    setDescription(value);
  }

  function handleCancel() {
    cleanInputFields();
    props.handleClose();
  }

  function handleClickAway() {
    setBorderColor('1px solid #A8A6AE');
  }

  function handleClickOnChipInput() {
    setBorderColor('1px solid #292336');
  }

  return (
    <Modal open={props.isOpen}>
      <Box style={fullScreenModalBackgroundStyle}>
        <Hidden mdDown>
          <Box style={modalCloseButtonBoxStyle}>
            <Grid container justifyContent="center" style={displayBlock}>
              <Grid item xs={12}>
                <IconButton
                  style={modalCloseButtonStyle}
                  color="default"
                  disableFocusRipple={true}
                >
                  <CloseIcon clicked={handleCancel} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box style={modalCloseTextStyle}>CLOSE</Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={fullScreenModalBackContainerStyle}
        >
          <Grid
            item
            xs={12}
            container
            style={{ maxWidth: '100%' }}
            justifyContent="center"
          >
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={whiteBackgroundInputWindow}
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: '30px',
                  marginLeft: '10%',
                  marginRight: '10%'
                }}
              >
                <Grid item xs={12} container justifyContent="center">
                  <Box
                    style={modalTitleStyleSmallerTopPadding}
                    fontWeight={700}
                  >
                    New project
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Name
                  </InputLabel>
                  <CustomInputWithFocus
                    required
                    placeholder="Project name..."
                    style={{
                      height: '40px',
                      marginBottom: '20px'
                    }}
                    value={projectName}
                    onChange={(e: { target: { value: string } }) =>
                      handleProjectNameChange(e.target.value)
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Description
                  </InputLabel>
                  <CustomInputWithFocus
                    placeholder="Description..."
                    minRows={2}
                    maxRows={2}
                    multiline={true}
                    style={{ marginBottom: '20px', marginTop: '-3px' }}
                    value={description}
                    onChange={(e: { target: { value: string } }) =>
                      handleDescriptionChange(e.target.value)
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Share with
                  </InputLabel>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <CustomChipInput
                      placeholder="E-mail address..."
                      value={newContacts}
                      style={{
                        height: '64px',
                        paddingBottom: '2px',
                        marginBottom: '20px',
                        border: borderColor
                      }}
                      onAdd={(contact: string) => handleAddContact(contact)}
                      onClick={handleClickOnChipInput}
                      chipRenderer={({ value }: any, key: any) => (
                        <CustomChip
                          key={key}
                          className="chip"
                          clickable={false}
                          label={value}
                          onDelete={() => handleDeleteContact(value)}
                          deleteIcon={
                            <DeleteIcon
                              clicked={handleDeleteContact}
                              value={value}
                            />
                          }
                        />
                      )}
                    />
                  </ClickAwayListener>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <InputLabel shrink style={modalInputLabelTextStyle}>
                    Message
                  </InputLabel>
                  <CustomInputWithFocus
                    placeholder="Invite message..."
                    minRows={2}
                    maxRows={2}
                    multiline={true}
                    style={{ marginTop: '-3px' }}
                    value={messageText}
                    onChange={(e: { target: { value: string } }) =>
                      handleMessageTextChange(e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            style={bottomButtonModalStyle}
          >
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: '0px 10px 0px 0px' }}
            >
              <Box style={{ width: '120px' }}>
                <CancelButton
                  textValue="CANCEL"
                  height="32px"
                  onclick={handleCancel}
                ></CancelButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
              alignItems="center"
              style={{ padding: '0px 0px 0px 10px' }}
            >
              <Box
                style={{
                  width: '120px'
                }}
              >
                <MainButton
                  textValue="SAVE"
                  height="32px"
                  onclick={handleSave}
                ></MainButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
