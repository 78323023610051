import { ButtonBase, Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { ButtonProps, UploadButtonProps } from '../../types/interfaces';

const mainButtonOuterRectangle = {
  border: '1px solid #292336',
  boxSizing: 'border-box',
  borderRadius: '5px',
  boxShadow: '0px 3px 6px rgba(41, 35, 54, 0.3)'
} as React.CSSProperties;
const mainButtonInnerRectangle = {
  border: '2px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const mainButtonStyle = {
  backgroundColor: '#292336',
  borderRadius: '3px',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  lineHeight: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#ffffff',
  letterSpacing: '0.06em'
};

const secondaryButtonOuterRectangle = {
  border: '1px solid #7F7986',
  boxSizing: 'border-box',
  borderRadius: '4px'
} as React.CSSProperties;
const secondaryButtonInnerRectangle = {
  border: '3px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '4px'
} as React.CSSProperties;
const secondaryButtonStyle = {
  backgroundColor: '#7F7986',
  borderRadius: '2px',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#ffffff',
  letterSpacing: '0.02em'
};

const discardButtonOuterRectangle = {
  border: '1px solid #E18A88',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const discardButtonInnerRectangle = {
  border: '3px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '5px'
} as React.CSSProperties;
const discardButtonStyle = {
  backgroundColor: '#E18A88',
  borderRadius: '3px',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#f8f8f9',
  letterSpacing: '0.06em'
};

const defaultButtonOuterRectangle = {
  border: '1px solid #7F7986',
  boxSizing: 'border-box',
  borderRadius: '4px'
} as React.CSSProperties;
const defaultButtonInnerRectangle = {
  border: '3px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '4px'
} as React.CSSProperties;
const defaultButtonStyle = {
  backgroundColor: '#F4F4F4',
  borderRadius: '2px',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#292336',
  letterSpacing: '0.02em'
};

const cancelButtonOuterRectangle = {
  border: '1px solid #A8A6AE',
  boxSizing: 'border-box',
  borderRadius: '4px'
} as React.CSSProperties;
const cancelButtonInnerRectangle = {
  border: '3px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '4px'
} as React.CSSProperties;
const cancelButtonStyle = {
  backgroundColor: '#F4F4F4',
  borderRadius: '2px',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#A8A6AE',
  letterSpacing: '0.02em'
};

const lightMenuButtonStyle = {
  backgroundColor: '#FFFFFF',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'Prompt',
  color: '#544E5E'
};

const darkMenuButtonStyle = {
  backgroundColor: '#7F7986',
  borderRadius: '2px',
  border: '1px solid #736C7A',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'Prompt',
  color: '#FFFFFF'
};

const uploadButtonStyle = {
  backgroundColor: '#292336',
  borderRadius: '2px',
  border: '1px solid #000000',
  height: '100%',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#FFFFFF',
  letterSpacing: '0.06em',
  cursor: 'pointer'
};

export function MainButton(buttonProps: ButtonProps) {
  return (
    <Box style={mainButtonOuterRectangle}>
      <Box style={mainButtonInnerRectangle}>
        <Box style={{ height: buttonProps.height }}>
          <ButtonBase
            disableRipple={true}
            style={mainButtonStyle}
            onClick={buttonProps.onclick}
            disabled={buttonProps.disabled}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function SecondaryButton(buttonProps: ButtonProps) {
  return (
    <Box style={secondaryButtonOuterRectangle}>
      <Box style={secondaryButtonInnerRectangle}>
        <Box style={{ height: buttonProps.height }}>
          <ButtonBase
            disableRipple={true}
            style={secondaryButtonStyle}
            onClick={buttonProps.onclick}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function DiscardButton(buttonProps: ButtonProps) {
  return (
    <Box style={discardButtonOuterRectangle}>
      <Box style={discardButtonInnerRectangle}>
        <Box style={{ height: buttonProps.height }}>
          <ButtonBase
            disableRipple={true}
            style={discardButtonStyle}
            onClick={buttonProps.onclick}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function DefaultButton(buttonProps: ButtonProps) {
  return (
    <Box style={defaultButtonOuterRectangle}>
      <Box style={defaultButtonInnerRectangle}>
        <Box style={{ height: buttonProps.height }}>
          <ButtonBase
            disableRipple={true}
            style={defaultButtonStyle}
            onClick={buttonProps.onclick}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function CancelButton(buttonProps: ButtonProps) {
  return (
    <Box style={cancelButtonOuterRectangle}>
      <Box style={cancelButtonInnerRectangle}>
        <Box style={{ height: buttonProps.height }}>
          <ButtonBase
            disableRipple={true}
            style={cancelButtonStyle}
            onClick={buttonProps.onclick}
          >
            {buttonProps.textValue}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export function LightMenuButton(buttonProps: ButtonProps) {
  return (
    <Box style={{ height: buttonProps.height }}>
      <ButtonBase
        disableRipple={true}
        style={lightMenuButtonStyle}
        onClick={buttonProps.onclick}
      >
        {buttonProps.textValue}
      </ButtonBase>
    </Box>
  );
}

export function LightMenuButtonHover(buttonProps: ButtonProps) {
  const [buttonBgColour, setButtonBgColour] = useState('#FFFFFF');

  const lightMenuButtonStyleHover = {
    backgroundColor: `${buttonBgColour}`,
    height: '100%',
    width: '100%',
    fontSize: '1.4rem',
    fontFamily: 'Prompt',
    color: '#544E5E',
    borderRadius: '3px'
  };

  return (
    <Box style={{ height: buttonProps.height }}>
      <ButtonBase
        disableRipple={true}
        style={lightMenuButtonStyleHover}
        onClick={buttonProps.onclick}
        onMouseEnter={() => {
          setButtonBgColour('rgba(168, 166, 174, 0.2)');
        }}
        onMouseLeave={() => {
          setButtonBgColour('#FFFFFF');
        }}
      >
        {buttonProps.textValue}
      </ButtonBase>
    </Box>
  );
}

export function DarkMenuButton(buttonProps: ButtonProps) {
  return (
    <Box style={{ height: buttonProps.height }}>
      <ButtonBase
        disableRipple={true}
        style={darkMenuButtonStyle}
        onClick={buttonProps.onclick}
      >
        {buttonProps.textValue}
      </ButtonBase>
    </Box>
  );
}

export function UploadButton(buttonProps: UploadButtonProps) {
  return (
    <Box>
      <label htmlFor="upload">
        <Box style={{ height: buttonProps.height }}>
          <Grid
            style={uploadButtonStyle}
            container
            justifyContent="center"
            alignItems="center"
          >
            {buttonProps.textValue}
          </Grid>
        </Box>
      </label>
      <input
        id="upload"
        type="file"
        style={{ visibility: 'hidden', height: 0 }}
        onChange={buttonProps.onChange}
      />
    </Box>
  );
}

export function SecondaryUploadButton(buttonProps: UploadButtonProps) {
  return (
    <Box>
      <label htmlFor="upload">
        <Box style={{ cursor: 'pointer' }}>
          <Box style={secondaryButtonOuterRectangle}>
            <Box style={secondaryButtonInnerRectangle}>
              <Box style={{ height: buttonProps.height }}>
                <Grid
                  style={secondaryButtonStyle}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {buttonProps.textValue}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </label>
      <input
        id="upload"
        type="file"
        style={{ visibility: 'hidden', height: 0 }}
        onChange={buttonProps.onChange}
      />
    </Box>
  );
}
