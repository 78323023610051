import {
  Hidden,
  Drawer,
  Box,
  makeStyles,
  createStyles
} from '@material-ui/core';
import React from 'react';
import logoPath from '../../images/logo.png';
import { drawerStyle, sideMenuDrawerStyle } from '../../styles/styles';
import { LogoPanel } from '../reusable/LogoPanel';
import { SideMenuPanel } from './SideMenuPanel';
import { SideMenuDrawerPanelProps } from '../../types/interfaces';

const useStyles = makeStyles(() =>
  createStyles({
    drawerPaper: {
      borderRight: '1px solid #A8A8AE',
      overflowY: 'visible'
    }
  })
);

export function SideMenuDrawerPanel(props: SideMenuDrawerPanelProps) {
  const classes = useStyles();
  let drawer = (
    <Box style={sideMenuDrawerStyle}>
      <LogoPanel logoPath={logoPath} altText={'ARBIRA'} />
      <SideMenuPanel
        selectedProjectId={props.selectedProjectId}
        onProjectSelectionChanged={props.onProjectSelectionChanged}
        onViewModeChange={props.onViewModeChange}
      />
    </Box>
  );

  return (
    <Box>
      <Hidden smDown implementation="js">
        <Drawer
          elevation={0}
          style={drawerStyle}
          variant="permanent"
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.sideMenuOpen}
          onClose={props.onSideMenuOpenCloseAction}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Box>
  );
}
