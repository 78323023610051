import axios from 'axios';
import { InteractionRequiredAuthError } from 'msal';
import { Dispatch } from 'react';
import { authProvider } from '../authProvider';
import { FileType, User } from '../types/interfaces';
import { ArbiraActions, Types } from '../reducers/reducers';

export const baseUrl = 'https://arbirafunctions.azurewebsites.net/api';

let accessTokenRequest = {
  scopes: [
    'https://arbiraadb2c.onmicrosoft.com/6494ecea-b04d-4c19-bbdc-d9cac92eeaaa/Files.Read'
  ],
  account: authProvider.getAccount()
};

async function GetAccessToken() {
  return await authProvider
    .acquireTokenSilent(accessTokenRequest)
    .then(function(accessTokenResponse) {
      // Acquire token silent success
      return accessTokenResponse.accessToken;
    })
    .catch(function(error) {
      console.log(error);
      //Acquire token silent failure, and send an interactive request
      if (error instanceof InteractionRequiredAuthError) {
        authProvider
          .acquireTokenPopup(accessTokenRequest)
          .then(function(accessTokenResponse) {
            // Acquire token interactive success
            return accessTokenResponse.accessToken;
          })
          .catch(function(error) {
            // Acquire token interactive failure
            console.log(error);
          });
      }
      console.log(error);
    });
}

async function SendPostAuthRequest(
  url: string,
  data: any,
  dispatch: Dispatch<ArbiraActions>
) {
  document.body.style.cursor = 'wait';
  let accessToken = await GetAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  return await axios
    .post(url, data, config)

    .then(response => {
      document.body.style.cursor = 'default';
      return response.data;
    })

    .catch(function(error) {
      document.body.style.cursor = 'default';
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message:
              'API error: something went wrong while trying to add data.',
            severity: 'error'
          }
        }
      });
      console.log(error.toJSON());
    });
}

async function SendGetAuthRequest(
  url: string,
  dispatch: Dispatch<ArbiraActions>
) {
  document.body.style.cursor = 'wait';
  let accessToken = await GetAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  return await axios
    .get(url, config)
    .then(response => {
      document.body.style.cursor = 'default';
      return response.data;
    })
    .catch(function(error) {
      document.body.style.cursor = 'default';
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message:
              'API error: something went wrong while trying to fetch data from server.',
            severity: 'error'
          }
        }
      });
      console.log(error.toJSON());
    });
}

async function SendPutAuthRequest(
  url: string,
  data: any,
  dispatch: Dispatch<ArbiraActions>
) {
  document.body.style.cursor = 'wait';
  let accessToken = await GetAccessToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  return await axios
    .put(url, data, config)
    .then(response => {
      document.body.style.cursor = 'default';
      return response.data;
    })
    .catch(function(error) {
      document.body.style.cursor = 'default';
      dispatch({
        type: Types.SetSnackbar,
        payload: {
          snackbarData: {
            open: true,
            message:
              'API error: something went wrong while trying to update data.',
            severity: 'error'
          }
        }
      });
      console.log(error.toJSON());
    });
}

export async function Login(
  firstName: string,
  lastName: string,
  email: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/login';
  let data = {
    firstName: firstName,
    lastName: lastName,
    company: '',
    imgPath: '',
    email: email
  };
  return await SendPostAuthRequest(url, data, dispatch);
}

export async function GetUser(
  userId: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/user/' + userId;
  return await SendGetAuthRequest(url, dispatch);
}

export async function UpdateUser(
  userId: string,
  firstName: string,
  lastName: string,
  company: string,
  imgPath: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/user/' + userId;
  let data = {
    firstName: firstName,
    lastName: lastName,
    company: company,
    imgPath: imgPath
  };
  await SendPutAuthRequest(url, data, dispatch);
}

export async function GetProjectsOfUser(
  userId: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/user/' + userId + '/project';
  return await SendGetAuthRequest(url, dispatch);
}

export async function AddProject(
  projectName: string,
  description: string,
  user: User,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/project';
  let data = {
    name: projectName,
    description: description,
    userId: user.id,
    firstNameOfUser: user.firstName,
    lastNameOfUser: user.lastName,
    creationTime: new Date().toJSON()
  };
  return await SendPostAuthRequest(url, data, dispatch);
}

export async function GetProject(
  projectId: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/project/' + projectId;
  return await SendGetAuthRequest(url, dispatch);
}

export async function UpdateProject(
  projectId: string,
  projectName: string,
  description: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/project/' + projectId;
  let data = {
    name: projectName,
    description: description
  };
  await SendPutAuthRequest(url, data, dispatch);
}

export async function ShareProject(
  projectId: string,
  newContacts: string[],
  message: string,
  dispatch: Dispatch<ArbiraActions>
) {
  if (newContacts.length > 0) {
    let url = baseUrl + '/project/' + projectId + '/user';
    let data = {
      emailsToShareWith: newContacts,
      message: message
    };
    await SendPostAuthRequest(url, data, dispatch);
  }
}

export async function ArchiveProject(
  projectId: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/project/' + projectId + '/archiving';
  let data = {
    archived: true
  };
  await SendPostAuthRequest(url, data, dispatch);
}

export async function AddSimulation(
  projectId: string,
  projectName: string,
  name: string,
  description: string,
  user: User,
  simulationFile: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let robots = await getRobots(dispatch);
  let url = baseUrl + '/project/' + projectId + '/simulation';
  let data = {
    name: name,
    userId: user.id,
    firstNameOfUser: user.firstName,
    lastNameOfUser: user.lastName,
    creationTime: new Date().toJSON(),
    description: description,
    screenCapture: '',
    totalFeedbackCount: 0,
    projectId: projectId,
    projectName: projectName,
    robotId: robots[0].id,
    modelOfRobot: robots[0].model,
    manufacturerOfRobot: robots[0].manufacturer,
    lengthInMilliSeconds: 0,
    simulationFile: simulationFile,
    feedbacks: []
  };
  await SendPostAuthRequest(url, data, dispatch);
}

async function getRobots(dispatch: Dispatch<ArbiraActions>) {
  let url = baseUrl + '/robot';
  return await SendGetAuthRequest(url, dispatch);
}

export async function UpdateSimulation(
  simulationId: string,
  name: string,
  description: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/simulation/' + simulationId;
  let data = {
    name: name,
    description: description
  };
  await SendPutAuthRequest(url, data, dispatch);
}

export async function GetSimulation(
  simulationId: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/simulation/' + simulationId;
  return await SendGetAuthRequest(url, dispatch);
}

export async function AddNewCommentForFeedback(
  simulationId: string,
  feedbackId: string,
  user: User,
  text: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url =
    baseUrl +
    '/simulation/' +
    simulationId +
    '/feedback/' +
    feedbackId +
    '/comment';
  let data = {
    userId: user.id,
    firstNameOfUser: user.firstName,
    lastNameOfUser: user.lastName,
    imgPathOfUser: user.imgPath,
    creationTime: new Date().toJSON(),
    text: text
  };
  await SendPostAuthRequest(url, data, dispatch);
}

export async function AddNewInnerCommentForFeedback(
  simulationId: string,
  feedbackId: string,
  commentid: string,
  user: User,
  text: string,
  dispatch: Dispatch<ArbiraActions>
) {
  let url =
    baseUrl +
    '/simulation/' +
    simulationId +
    '/feedback/' +
    feedbackId +
    '/comment/' +
    commentid +
    '/comment';
  let data = {
    userId: user.id,
    firstNameOfUser: user.firstName,
    lastNameOfUser: user.lastName,
    imgPathOfUser: user.imgPath,
    creationTime: new Date().toJSON(),
    text: text
  };
  await SendPostAuthRequest(url, data, dispatch);
}

export async function UploadFile(
  file: File,
  typeOfFile: FileType,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/file';
  let data = {
    fileName: file.name,
    typeOfFile: typeOfFile as number,
    base64FileString: await CreateBase64StrFromFile(file)
  };

  return await SendPostAuthRequest(url, data, dispatch);
}

export async function UploadBase64File(
  base64file: string,
  filename: string,
  typeOfFile: FileType,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/file';
  let data = {
    fileName: filename,
    typeOfFile: typeOfFile as number,
    base64FileString: base64file
  };

  return await SendPostAuthRequest(url, data, dispatch);
}

export async function CreateBase64StrFromFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(
        (reader.result as string).replace('data:', '').replace(/^.+,/, '')
      );
    };
    reader.onerror = err => reject(err);

    reader.readAsDataURL(file);
  });
}

export async function DownloadFile(
  fileName: string,
  typeOfFile: FileType,
  dispatch: Dispatch<ArbiraActions>
) {
  let url = baseUrl + '/file/' + fileName + '/' + (typeOfFile as number);
  let ret = (await SendGetAuthRequest(url, dispatch)) as string;
  return ret;
}
