import { Box, Grid, Link } from '@material-ui/core';
import {
  ActiveTimeSymbolHorizontalIcon,
  InactiveTimeSymbolHorizontalIcon,
  ReplyArrowIcon,
  CommentSeparatorLineIcon
} from '../reusable/IconImages';
import { CommentPanel } from './CommentPanel';
import React, { useState } from 'react';
import { FeedbackItemProps } from '../../types/interfaces';
import { AddNewCommentItem } from './AddNewCommentItem';
import { formatSimulationLength } from '../../helper/helper';

export function FeedbackItem(props: FeedbackItemProps) {
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [newCommentForScreenShot, setNewCommentForScreenShot] = useState('');

  function handleAddCommentForFeedback() {
    props.onAddCommentForFeedback(props.feedback.id, newCommentForScreenShot);
    setNewCommentForScreenShot('');
    setShowCommentBox(false);
  }

  return (
    <Box id={'feedback' + props.feedback.id} paddingBottom="15px">
      <Box style={{ position: 'relative' }}>
        <Box
          style={{
            position: 'absolute',
            left: '-28px',
            bottom: '-21px'
          }}
        >
          {props.feedback.id === props.selectedfeedbackId ? (
            <ActiveTimeSymbolHorizontalIcon />
          ) : (
            <InactiveTimeSymbolHorizontalIcon />
          )}
        </Box>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid container item xs={9}>
          <Box
            style={{
              fontFamily: 'Prompt',
              fontSize: '1.3rem',
              lineHeight: '2rem',
              color: '#293336'
            }}
          >
            {formatSimulationLength(
              props.feedback.positionInMilliseconds,
              true
            )}
          </Box>
          <Box
            style={{
              fontFamily: 'Prompt',
              fontSize: '1.3rem',
              lineHeight: '2rem',
              color: '#A8A6AE',
              paddingLeft: '7px'
            }}
          >
            #
            {formatSimulationLength(
              props.feedback.positionInMilliseconds,
              true,
              true
            )}{' '}
            -{' '}
            <Link
              underline="always"
              style={{ color: 'inherit', cursor: 'pointer' }}
              onClick={props.onJumpToFeedback}
            >
              jump to screenshot
            </Link>
          </Box>
        </Grid>
        {showCommentBox ? null : (
          <Grid item>
            <Box
              style={{
                fontFamily: 'Prompt',
                fontSize: '1.3rem',
                lineHeight: '2rem',
                color: '#A8A6AE'
              }}
            >
              <Link
                underline="always"
                style={{ color: 'inherit', cursor: 'pointer' }}
                onClick={() => setShowCommentBox(true)}
              >
                Add comment
              </Link>
            </Box>
          </Grid>
        )}
      </Grid>
      {showCommentBox ? (
        <AddNewCommentItem
          commentText={newCommentForScreenShot}
          onInputChanged={setNewCommentForScreenShot}
          onCancelPressed={() => setShowCommentBox(false)}
          onCommentAdded={() => handleAddCommentForFeedback()}
        />
      ) : null}
      {props.feedback.comments.map(comment => (
        <Grid container key={props.feedback.id + comment.id}>
          <CommentPanel
            comment={comment}
            projectId={props.projectId}
            simulationId={props.simulationId}
            feedbackId={props.feedback.id}
            parentCommentId={comment.id}
          />
          {comment.comments.map(reply => (
            <Grid style={{ marginLeft: '20px' }} key={reply.id}>
              <Box style={{ position: 'relative' }}>
                <Box
                  style={{
                    position: 'absolute',
                    left: '-20px',
                    bottom: '-30px'
                  }}
                >
                  <ReplyArrowIcon />
                </Box>
              </Box>
              <CommentPanel
                comment={reply}
                projectId={props.projectId}
                simulationId={props.simulationId}
                feedbackId={props.feedback.id}
                parentCommentId={comment.id}
              />
            </Grid>
          ))}
          <Grid container style={{ position: 'relative' }}>
            <Box
              style={{
                position: 'absolute',
                left: '-17px',
                top: '-25px'
              }}
            >
              <CommentSeparatorLineIcon />
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}
