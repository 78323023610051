import { Grid, IconButton, Box, Link } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { UserIcon } from '../reusable/IconImages';
import { CommentPanelProps } from '../../types/interfaces';
import { AddNewCommentItem } from './AddNewCommentItem';
import { getTimePassed } from '../../helper/helper';
import { AppContext } from '../../context/context';
import { Types } from '../../reducers/reducers';
import {
  AddNewInnerCommentForFeedback,
  GetProject,
  GetSimulation
} from '../../helper/apiHelper';
import {
  RefreshCommenterUserIcon,
  RefreshSimulationCoverImage,
  RefreshSharedWithUserImage
} from '../../helper/imageHelper';

export function CommentPanel(props: CommentPanelProps) {
  const { state, dispatch } = useContext(AppContext);
  const [newComment, setNewComment] = useState('');
  const [showCommentBox, setShowCommentBox] = useState(false);

  async function handleAddReplyForFeedback() {
    await AddNewInnerCommentForFeedback(
      props.simulationId,
      props.feedbackId,
      props.parentCommentId,
      state.loggedInUser,
      newComment,
      dispatch
    );
    let selectedSimulation = await GetSimulation(
      state.selectedSimulation.id,
      dispatch
    );
    dispatch({
      type: Types.SetSelectedSimulation,
      payload: {
        simulation: selectedSimulation
      }
    });
    refreshProjectAfterCommentAdded();
    RefreshCommenterUserIcon(
      selectedSimulation,
      dispatch,
      state.downloadedImages
    );
    setNewComment('');
    setShowCommentBox(false);
  }

  async function refreshProjectAfterCommentAdded() {
    let newProject = await GetProject(props.projectId, dispatch);
    dispatch({
      type: Types.UpdateProject,
      payload: {
        project: newProject
      }
    });
    RefreshSimulationCoverImage(newProject, dispatch, state.downloadedImages);
    RefreshSharedWithUserImage(newProject, dispatch, state.downloadedImages);
  }

  return (
    <Grid container style={{ paddingTop: '5px', paddingBottom: '15px' }}>
      <Grid container alignItems="center">
        <IconButton
          style={{
            height: '34px',
            width: '34px',
            padding: '0px',
            margin: '0px 0px 0px 0px',
            border: '1px solid #7F7986',
            boxShadow: 'none'
          }}
          color="default"
          disableRipple={true}
        >
          <UserIcon
            userId={props.comment.userId}
            firstNameOfUser={props.comment.firstNameOfUser}
            lastNameOfUser={props.comment.lastNameOfUser}
            imgPathOfUser={props.comment.imgPathOfUser}
            downloadedImage={props.comment.downloadedImage}
            emailOfUser={''}
            height={'30px'}
            marginLeft={'0px'}
          />
        </IconButton>
        <Box
          style={{
            paddingLeft: '5px',
            fontFamily: 'Prompt',
            fontSize: '1.3rem',
            lineHeight: '2rem',
            letterSpacing: '0.02em',
            fontWeight: 'bold',
            color: '#293336'
          }}
        >
          {props.comment.firstNameOfUser + ' ' + props.comment.lastNameOfUser}
        </Box>
        <Box
          style={{
            paddingLeft: '10px',
            fontFamily: 'Prompt',
            fontSize: '1.3rem',
            lineHeight: '2rem',
            color: '#293336',
            opacity: 0.3
          }}
        >
          {getTimePassed(new Date(props.comment.creationTime), '')}
        </Box>
      </Grid>
      <Grid
        container
        style={{
          fontFamily: 'Prompt',
          fontSize: '1.4rem',
          lineHeight: '1.7rem',
          color: '#293336',
          paddingTop: '5px'
        }}
      >
        {props.comment.text}
      </Grid>
      {showCommentBox ? (
        <AddNewCommentItem
          commentText={newComment}
          onInputChanged={setNewComment}
          onCancelPressed={() => setShowCommentBox(false)}
          onCommentAdded={() => handleAddReplyForFeedback()}
        />
      ) : (
        <Grid
          container
          style={{
            paddingTop: '15px',
            fontFamily: 'Prompt',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            color: '#293336'
          }}
        >
          <Link
            underline="always"
            style={{ color: 'inherit', cursor: 'pointer' }}
            onClick={() => setShowCommentBox(true)}
          >
            Reply to comment
          </Link>
        </Grid>
      )}
    </Grid>
  );
}
