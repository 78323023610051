// ### Custom
export const displayBlock = {
  display: 'block'
};
export const displayFlex = {
  display: 'flex'
};
export const autoWidth = {
  width: 'auto'
};

// ### Appbar
export const appBarSpacer = {
  height: '170px'
};
export const sideMenuSpacer = {
  width: '320px'
};
// ### Drawer
export const drawerStyle = {
  width: '320px',
  flexShrink: 0
};
// ### Logo panel
export const logoPanelStyle = {
  margin: '1.7rem 0px 1.6rem 2rem'
};

// ### Search panel
export const searchPanel = {
  padding: '0px 0px 0px 15px',
  display: 'flex',
  height: '40px',
  borderRadius: '52px',
  boxShadow: 'none'
};
export const searchButton = {
  height: '24px',
  width: '24px',
  boxShadow: 'none',
  padding: '7px 10px 0px 2px'
};
export const searchText = {
  fontFamily: 'Prompt',
  color: '#293236',
  fontSize: '1.6rem'
};
export const searchPanelDark = {
  padding: '0px 0px 0px 15px',
  display: 'flex',
  height: '30px',
  width: '90%',
  borderRadius: '52px',
  boxShadow: 'none',
  backgroundColor: '#E4E4E4'
};
export const searchButtonDark = {
  height: '20px',
  width: '20px',
  boxShadow: 'none',
  padding: '6px 12px 0px 0px'
};
export const searchTextDark = {
  fontFamily: 'Prompt',
  color: '#293236',
  fontSize: '1.4rem',
  lineHeight: '2.1rem'
};

// ### Top menu panel
export const topMenuPadding = {
  padding: '10px 20px 10px 0px'
};
export const topMenuButton = {
  height: '40px',
  width: '40px',
  padding: '4px 0px 0px 0px',
  margin: '0px 3px 0px 3px',
  borderColor: 'lightgray',
  backgroundColor: '#FFFFFF',
  boxShadow: 'none'
};
export const topMenuButtonWithNoPadding = {
  height: '40px',
  width: '40px',
  padding: '0px',
  margin: '0px 0px 0px 20px',
  border: '1px solid #7F7986',
  boxShadow: 'none'
};

// ### Data view panel
export const dataViewActionPanelNewRobotSimulationButtonStyle = {
  width: '220px'
};

// ### Simulation item
export const simulationItemStyle = {
  backgroundColor: '#FFFFFF',
  borderRadius: '3px',
  width: '100%',
  margin: '5px',
  boxShadow: 'none',
  minwidth: '300px'
};
export const simulationItemHoverStyle = {
  backgroundColor: '#FFFFFF',
  borderRadius: '3px',
  width: '100%',
  margin: '5px',
  boxShadow: '0px 4px 20px rgba(41, 35, 54, 0.2)',
  minwidth: '300px'
};
export const simulationItemNameStyle = {
  fontSize: '1.8rem',
  fontFamily: 'Prompt',
  color: '#000000',
  paddingTop: '1px',
  letterSpacing: '-0.005em'
};
export const simulationItemCommentCountStyle = {
  color: '#292336',
  fontSize: '1.2rem',
  fontFamily: 'Prompt',
  paddingLeft: '3px'
};
export const simulationItemTextStyle = {
  fontSize: '1.4rem',
  fontFamily: 'Prompt',
  color: '#292336'
};
export const simulationItemCardAction = {
  height: '110px',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '10px'
};
export const simulationItemMoreButton = {
  width: '24px',
  height: '24px',
  padding: '0px',
  background: 'rgba(255, 255, 255, 0.6)',
  borderRadius: '2px'
};
export const simulationItemTimeBox = {
  background: 'rgba(45, 37, 58, 0.85)',
  border: '1px solid rgba(45, 37, 58, 0.61)',
  borderRadius: '3px',
  width: '50px',
  fontFamily: 'Prompt',
  fontSize: '1.1rem',
  letterSpacing: '0.055em',
  color: '#FFFFFF',
  opacity: 1!
};
export const simulationItemCardCardContent = {
  paddingTop: '15px',
  paddingLeft: '20px',
  paddingRight: '15px',
  minwidth: '300px'
};
export const simulationItemDescription = {
  fontSize: '1.2rem',
  fontFamily: 'Prompt',
  color: '#292336',
  paddingTop: '10px',
  marginBottom: '-8px'
};

// ### Top menu action panel
export const topMenuGeneralActionPanelStyle = {
  padding: '10px 20px 10px 20px'
};
export const topMenuGeneralActionPanelNameStyle = {
  fontSize: '2.2rem',
  lineHeight: '3.3rem',
  fontFamily: 'Prompt',
  padding: '0px 0px 0px 20px',
  color: '#292336'
};

export const projectNameStyle = {
  fontSize: '2rem',
  lineHeight: '3rem',
  fontFamily: 'Prompt',
  padding: '0px 0px 16px 0px',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#292336'
};

export const accountText = {
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontSize: '1.4rem',
  fontWeight: 400,
  lineHeight: '2.1rem',
  display: 'flex',
  alignItems: 'center',
  color: '#292336',
  marginLeft: '22px',
  cursor: 'pointer'
};

// ### Side menu panel
export const sideMenuDrawerStyle = {
  width: '320px'
};
export const sideMenuStyle = {
  padding: '0px 0px 0px 0px',
  color: '#000000',
  boxShadow: 'none'
};
export const sideMenuNewProjectButtonPadding = {
  padding: '24px 20px 8px 20px'
};
export const sideMenuProjectListStyle = {
  padding: '10px 20px 10px 20px',
  margin: '10px 2px 0px 2px',
  overflow: 'scroll',
  height: 'calc(100vh - 278px)'
};
export const sideMenuSettingsStyle = {
  height: '104px'
};
export const sideMenuSettingsButtonSpacer1Style = {
  padding: '10px 20px 20px 20px'
};
export const sideMenuSettingsButtonSpacer2Style = {
  padding: '24px 20px 17px 20px'
};
export const sideMenuSettingsLinkSpacerStyle = {
  padding: '0px 20px 0px 20px'
};
export const sideMenuSettingsButtonStyle = {
  border: '1px solid rgba(0, 0, 0, 0.21)',
  backgroundColor: 'rgba(45, 37, 58, 0.02)',
  borderRadius: '3px',
  height: '38px',
  width: '100%',
  fontSize: '1.4rem',
  fontFamily: 'OpticianSans',
  color: '#2D253A'
};
export const sideMenuSettingsHrStyle = {
  border: 'none',
  borderTop: '1px solid #E8E8E8'
};
export const sideMenuSettingsLinkStyle = {
  color: 'inherit',
  padding: '0px 3px',
  cursor: 'pointer',
  fontSize: '1.1rem',
  fontFamily: 'Prompt'
};

// ### Side menu item
export const sideMenuItemStyle = {
  borderRadius: '3px',
  border: '1px solid #ffffff'
};
export const sideMenuItemSelectedStyle = {
  backgroundColor: '#EFEFEF',
  borderRadius: '3px',
  border: '1px solid #E73636',
  color: '#E73636'
};
export const sideMenuItemButtonStyle = {
  height: '50px',
  width: ' 100%',
  justifyContent: 'left'
};
export const sideMenuItemBoxStyle = {
  backgroundColor: '#C4C4C4',
  width: '40px',
  height: '30px',
  borderRadius: '2px',
  margin: '0px 0px 0px 10px'
};
export const sideMenuItemNameStyle = {
  fontSize: '1.4rem',
  padding: '0px 0px 0px 15px',
  fontFamily: 'Prompt',
  fontWeight: 600
};
export const sideMenuItemMoreButton = {
  width: '24px',
  height: '24px',
  padding: '0px',
  marginRight: '8px'
};
export const sideMenuItemPopup = {
  position: 'absolute' as 'absolute',
  width: '479px',
  height: '688px',
  backgroundColor: '#F7F7F7',
  borderRadius: '4px'
};

export const sideMenuItemDetails = {
  marginLeft: '65px',
  marginRight: '18px',
  marginBottom: '20px',
  color: '#7F7986',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1.2rem',
  lineHeight: '130%',
  alignItems: 'center'
};

// ### Robot model item
export const robotModelItemGridStyle = {
  padding: '10px',
  background: '#FFFFFF',
  borderRadius: '4px',
  marginBottom: '6px',
  height: '66px'
};
export const robotModelItemGridHoverStyle = {
  padding: '10px',
  background: '#FFFFFF',
  borderRadius: '4px',
  marginBottom: '6px',
  height: '66px',
  boxShadow: '0px 4px 20px rgba(41, 35, 54, 0.2)'
};
export const robotModelItemUsageTextStyle = {
  fontFamily: 'Prompt',
  fontSize: '1.4rem',
  marginRight: '30px'
};
export const robotModelItemNameStyle = {
  fontFamily: 'Prompt',
  fontSize: '1.8rem',
  color: '#292336'
};
export const robotModelItemNameHoverStyle = {
  fontFamily: 'Prompt',
  fontSize: '1.8rem',
  color: '#E73636'
};

// ### project edit modal
export const projectEditModalStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute' as 'absolute',
  padding: '0px',
  backgroundColor: '#F7F7F7',
  borderRadius: '4px',
  outline: 'none'
};
export const projectEditModalInnerContainerStyle = {
  width: '480px',
  height: 'auto',
  maxHeight: 'calc(100vh - 40px)',
  overflow: 'scroll',
  borderRadius: '4px'
};
export const projectEditModalBlockStyle = {
  marginTop: '20px',
  padding: '0px 20px'
};
export const projectEditHeaderStyle = {
  fontFamily: 'Prompt',
  fontSize: '2.8rem',
  color: '#292336',
  paddingLeft: '30px',
  paddingTop: '20px'
};
export const projectEditHeaderInputLabelStyle = {
  marginLeft: '10px',
  color: '#292336',
  opacity: 0.5,
  fontFamily: 'Prompt',
  fontSize: '1.2rem'
};
export const projectEditAccordionHeaderStyle = {
  fontFamily: 'OpticianSans',
  fontSize: '1.6rem',
  color: '#292336',
  paddingLeft: '5px'
};

// new robot model modal
export const newRobotModelModalStyle = {
  top: '40px',
  left: '40px',
  transform: 'translate(-20px, -20px)',
  position: 'absolute' as 'absolute',
  padding: '0px',
  backgroundColor: '#F7F7F7',
  borderRadius: '4px',
  outline: 'none'
};
export const newRobotModelModalInnerContainerStyle = {
  width: 'calc(100vw - 40px)',
  height: 'calc(100vh - 40px)',
  overflow: 'scroll',
  borderRadius: '4px'
};
export const modalCloseButtonBoxStyle = {
  position: 'absolute' as 'absolute',
  zIndex: 1041,
  top: '20px',
  right: '20px'
};
export const modalCloseButtonStyle = {
  borderColor: 'lightgray',
  boxShadow: 'none',
  height: '34px',
  width: '34px',
  padding: '0px'
};
export const modalCloseTextStyle = {
  paddingTop: '5px',
  fontFamily: 'OpticianSans',
  fontSize: '1.2rem',
  color: '#292336',
  opacity: 0.5
};
export const newRobotModelModalTitleStyle = {
  color: '#292336',
  fontFamily: 'Prompt',
  fontSize: '2.8rem',
  paddingTop: '70px',
  paddingBottom: '60px'
};
export const newRobotModelModalHeaderStyle = {
  color: '#292336',
  fontFamily: 'OpticianSans',
  fonsSize: '16px'
};
export const newRobotModelModalTopContainerBox = {
  borderBottom: '1px solid #A8A6AE'
};
export const newRobotModelModalBottomContainerBox = {
  borderBottom: '1px solid #A8A6AE',
  height: '50vh'
};
export const newRobotModelModalInnerLeftContainerBox = {
  borderRight: '1px solid #A8A6AE'
};
export const modalInputLabelTextStyle = {
  marginLeft: '15px',
  color: '#292336',
  opacity: 0.5,
  fontFamily: 'Prompt',
  fontSize: '1.2rem'
};

// new simulation modal
export const newSimulationModalTitleStyle = {
  color: '#292336',
  fontFamily: 'Prompt',
  fontSize: '2.8rem',
  paddingTop: '45px',
  paddingBottom: '15px'
};

export const modalTitleStyleSmallerTopPadding = {
  color: '#292336',
  fontFamily: 'Prompt',
  fontSize: '2.8rem',
  paddingTop: '5px',
  paddingBottom: '15px'
};

export const uploadSuccessTitle = {
  color: '#292336',
  fontFamily: 'Prompt',
  fontSize: '2.8rem',
  paddingTop: '139px',
  alignItems: 'center'
};

export const newSimulationUploadTextStyle = {
  marginLeft: '40px',
  paddingBottom: '3px',
  color: '#292336',
  opacity: 0.5,
  fontFamily: 'Prompt',
  fontSize: '1.2rem',
  lineHeight: '1.8rem'
};

export const newSimulationUploadTextBoldStyle = {
  marginLeft: '40px',
  paddingBottom: '10px',
  color: '#292336',
  fontFamily: 'Prompt',
  fontSize: '1.4rem',
  lineHeight: '2.1rem',
  fontWeight: 600
};

export const renderingText = {
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontSize: '1.4rem',
  fontWeight: 400,
  lineHeight: '2.1rem',
  color: '#292336',
  paddingTop: '185px'
};

// menu icon
export const menuIconStyle = {
  height: '40px',
  width: '40px',
  padding: '30px 0px 0px 10px',
  margin: '0px 0px 0px 0px',
  backgroundColor: '#F0f0f0',
  boxShadow: 'none',
  color: '#292336'
};

//FullScreenModal
export const fullScreenModalBackgroundStyle = {
  margin: '1rem',
  position: 'absolute' as 'absolute',
  padding: '0px',
  backgroundColor: '#F6F6F6',
  borderRadius: '8px',
  outline: 'none',
  minWidth: '300px',
  alignItems: 'center',
  justifyContent: 'center'
};

export const fullScreenModalBackContainerStyle = {
  width: 'calc(100vw - 2rem)',
  height: 'calc(100vh - 4rem)',
  borderRadius: '8px',
  backgroundColor: '#F6F6F6',
  minWidth: '300px',
  overflow: 'scroll',
  display: 'flex',
  minHeight: 'calc(100vh - 75px)',
  fileDirection: 'column'
};

export const whiteBackgroundInputWindow = {
  borderRadius: '8px 8px 0px 0px',
  padding: '50px 22px 15px 22px',
  marginTop: '38px',
  backgroundColor: '#FFFFFF',
  minHeight: '75vh',
  minWidth: '300px',
  maxWidth: '500px'
};

export const bottomButtonModalStyle = {
  position: 'fixed' as 'fixed',
  borderTop: '1px solid #A8A6AE',
  bottom: '0px',
  left: '10px',
  minHeight: '75px',
  width: 'calc(100vw - 20px)',
  backgroundColor: '#F6F6F6'
} as const;
