import {
  Feedback,
  Project,
  Simulation,
  SimulationBase
} from '../types/interfaces';

export function getSimulation(
  simulations: Simulation[],
  simulationId: string
): Simulation {
  return simulations.find(simulation => simulation.id === simulationId)!;
}

export function getProject(
  projectList: Project[],
  selectedProjectId: string
): Project {
  return projectList.find(project => project.id === selectedProjectId)!;
}

export function formatSimulationLength(
  length: number,
  isWithMillis: boolean,
  isWithhashTag?: boolean
): string {
  let toRet: string = '';

  let milliSec = length % 1000;
  length = Math.floor(length / 1000);
  // handle hours
  if (Math.floor(length / 3600) > 1) {
    //if (Math.floor(length / 3600) < 10) toRet += "0"; // add 0 before hours < 10
    toRet +=
      Math.floor(length / 3600) +
      (isWithhashTag === null || !isWithhashTag ? ':' : ''); // add hours and :
    length = length % 3600;
  }

  // handle seconds
  if (Math.floor(length / 60) < 1) toRet += '00:';
  else if (Math.floor(length / 60) < 10)
    toRet +=
      '0' +
      Math.floor(length / 60) +
      (isWithhashTag === null || !isWithhashTag ? ':' : '');
  else
    toRet +=
      Math.floor(length / 60) +
      (isWithhashTag === null || !isWithhashTag ? ':' : '');

  // handle seconds
  if (length % 60 < 10) toRet += '0';
  toRet += length % 60;

  if (isWithMillis) {
    toRet += isWithhashTag === null || !isWithhashTag ? '.' : '';
    if (Math.floor(milliSec / 100) > 0) toRet += milliSec;
    else {
      toRet += '0';
      if (Math.floor(milliSec / 10) > 0) toRet += milliSec;
      else {
        toRet += '0';
        toRet += milliSec;
      }
    }
  }
  return toRet;
}

export function getFeedbackTimeOverSimulationTimeString(
  simulationTime: number,
  feedback?: Feedback
): string {
  if (feedback === undefined) return 'No feedbacks...';
  else {
    return (
      formatSimulationLength(feedback.positionInMilliseconds, true) +
      ' / ' +
      formatSimulationLength(simulationTime, true)
    );
  }
}

export function getNextFeedback(
  feedbacks: Feedback[],
  selectedFeedback?: Feedback
): Feedback | undefined {
  if (selectedFeedback === undefined) return undefined;
  let currentIndex: number = feedbacks.findIndex(
    fb => fb.id === selectedFeedback.id
  );
  if (feedbacks.length - 1 === currentIndex) return feedbacks[0];
  return feedbacks[currentIndex + 1];
}

export function getPrevFeedback(
  feedbacks: Feedback[],
  selectedFeedback?: Feedback
): Feedback | undefined {
  if (selectedFeedback === undefined) return undefined;
  let currentIndex: number = feedbacks.findIndex(
    ss => ss.id === selectedFeedback.id
  );
  if (currentIndex === 0) return feedbacks[feedbacks.length - 1];
  return feedbacks[currentIndex - 1];
}

export function getTimePassed(creationTime: Date, preText: string): string {
  let toRet = preText;
  let difference = new Date().getTime() - creationTime.getTime();
  // happened days ago
  if (Math.floor(difference / (1000 * 3600 * 24)) > 0) {
    toRet += Math.floor(difference / (1000 * 3600 * 24));
    toRet +=
      Math.floor(difference / (1000 * 3600 * 24)) === 1 ? ' day' : ' days';
  }
  // happened hours ago
  else if (Math.floor(difference / (1000 * 3600)) > 0) {
    toRet += Math.floor(difference / (1000 * 3600));
    toRet += Math.floor(difference / (1000 * 3600)) === 1 ? ' hour' : ' hours';
  }
  // happened mins ago
  else if (Math.floor(difference / (1000 * 60)) > 0) {
    toRet += Math.floor(difference / (1000 * 60));
    toRet +=
      Math.floor(difference / (1000 * 60)) === 1 ? ' minute' : ' minutes';
  } else {
    Math.floor(difference / 1000);
    toRet += Math.floor(difference / 1000);
    toRet += Math.floor(difference / 1000) === 1 ? ' second' : ' seconds';
  }
  toRet += ' ago';
  return toRet;
}

export function formatDatetime(date: Date, format: string) {
  const _padStart = (value: number): string =>
    value.toString().padStart(2, '0');
  return format
    .replace(/yyyy/g, _padStart(date.getFullYear()))
    .replace(/dd/g, _padStart(date.getDate()))
    .replace(/mm/g, _padStart(date.getMonth() + 1))
    .replace(/hh/g, _padStart(date.getHours()))
    .replace(/ii/g, _padStart(date.getMinutes()))
    .replace(/ss/g, _padStart(date.getSeconds()));
}

export function getFilteredSimulations(
  searchText: string,
  simulations: SimulationBase[]
): SimulationBase[] {
  if (searchText.trim() === '') return simulations;

  return simulations.filter(
    simulation =>
      simulation.description.toLowerCase().includes(searchText.toLowerCase()) ||
      simulation.name.toLowerCase().includes(searchText.toLowerCase()) ||
      simulation.firstNameOfUser
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      simulation.lastNameOfUser.toLowerCase().includes(searchText.toLowerCase())
  );
}
