import {
  SimulationReviewProps,
  Feedback,
  ViewMode
} from '../../types/interfaces';
import React, { useContext, useState } from 'react';
import {
  Grid,
  Box,
  IconButton,
  ButtonBase,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import logoPath from '../../images/logo.png';
import { LogoPanel } from '../reusable/LogoPanel';
import { TopMenuGeneralActionPanel } from '../topMenu/TopMenuGeneralActionPanel';
import {
  BackIcon,
  ChevronRightIcon,
  UserIcon,
  TimeSymbolIcon,
  ActiveTimeSymbolIcon,
  NavLeftIcon,
  NavRightIcon
} from '../reusable/IconImages';
import { InviteUserButton } from '../project/InviteUserButton';
import { WhiteHorizontalLine } from '../reusable/HorizontalDivider';
import { CustomInput } from '../reusable/CustomInputs';
import { FeedbackItem } from './FeedbackItem';
import TopMenuPanel from '../topMenu/TopMenuPanel';
import { AppContext } from '../../context/context';
import { Types } from '../../reducers/reducers';
import {
  formatSimulationLength,
  getFeedbackTimeOverSimulationTimeString,
  getNextFeedback,
  getPrevFeedback,
  getProject,
  getTimePassed
} from '../../helper/helper';
import {
  AddNewCommentForFeedback,
  GetProject,
  GetSimulation
} from '../../helper/apiHelper';
import { DefaultFeedbackImageString } from './DefaultFeedbackImageString';
import {
  RefreshCommenterUserIcon,
  RefreshSharedWithUserImage,
  RefreshSimulationCoverImage,
  SetFeedbackImage
} from '../../helper/imageHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentSectionStyle: {
      [theme.breakpoints.down('sm')]: { minHeight: '40vh' },
      [theme.breakpoints.up('sm')]: { height: '63%' }
    },
    onMobileVisible: {
      [theme.breakpoints.up('md')]: { display: 'none' }
    },
    onMobileHidden: {
      [theme.breakpoints.down('sm')]: { display: 'none' }
    },
    simulationReviewTitleStyle: {
      [theme.breakpoints.down('xs')]: {
        padding: '76px 0px 11px 0px',
        fontSize: '2rem',
        lineHeight: '3rem',
        justifyContent: 'center',
        alignItem: 'center'
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.8rem',
        lineHeight: '4.2rem',
        padding: '0 20px 0 20px',
        margin: '8px 0px 0px 0px'
      }
    }
  })
);

export function SimulationReview(props: SimulationReviewProps) {
  const { state, dispatch } = useContext(AppContext);

  let project = getProject(state.projectList, props.selectedProjectId);
  let firstFeedback =
    state.selectedSimulation.feedbacks.length > 0
      ? state.selectedSimulation.feedbacks[0]
      : undefined;
  const [selectedFeedback, setSelectedFeedback] = useState(firstFeedback);

  const classes = useStyles();

  const [
    newCommentForSelectedFeedback,
    setNewCommentForSelectedFeedback
  ] = useState('');

  let commentPlaceHolder: string =
    selectedFeedback === undefined
      ? 'No feedbacks...'
      : 'Add comment to selected feedback (#' +
        formatSimulationLength(
          selectedFeedback.positionInMilliseconds,
          true,
          true
        ) +
        ')';

  function changeFeedbackSelection(feedback?: Feedback) {
    if (feedback !== undefined) {
      if (
        selectedFeedback === undefined ||
        feedback.id !== selectedFeedback.id
      ) {
        let el: HTMLElement | null = document.getElementById(
          'feedback' + feedback.id
        );
        if (el !== null) el.scrollIntoView();
        setSelectedFeedback(feedback);
      }
    }

    setSelectedFeedback(feedback);
    SetFeedbackImage(feedback, dispatch, state.downloadedImages);
  }

  function addCommentForSelectedFeedback() {
    if (selectedFeedback !== undefined)
      addCommentForFeedback(selectedFeedback.id, newCommentForSelectedFeedback);
    setNewCommentForSelectedFeedback('');
  }

  async function addCommentForFeedback(
    feedbackId: string,
    commentText: string
  ) {
    await AddNewCommentForFeedback(
      state.selectedSimulation.id,
      feedbackId,
      state.loggedInUser,
      commentText,
      dispatch
    );
    let selectedSimulation = await GetSimulation(
      state.selectedSimulation.id,
      dispatch
    );
    dispatch({
      type: Types.SetSelectedSimulation,
      payload: {
        simulation: selectedSimulation
      }
    });
    refreshProjectAfterCommentAdded();
    RefreshCommenterUserIcon(
      selectedSimulation,
      dispatch,
      state.downloadedImages
    );
  }

  async function refreshProjectAfterCommentAdded() {
    let project = await GetProject(props.selectedProjectId, dispatch);
    dispatch({
      type: Types.UpdateProject,
      payload: {
        project: project
      }
    });
    RefreshSimulationCoverImage(project, dispatch, state.downloadedImages);
    RefreshSharedWithUserImage(project, dispatch, state.downloadedImages);
  }

  function getFeedbackImage(selectedFeedback: Feedback | undefined) {
    if (
      selectedFeedback === undefined ||
      selectedFeedback.imagePath === undefined ||
      selectedFeedback.imagePath === '' ||
      selectedFeedback.downloadedImage === undefined ||
      selectedFeedback.downloadedImage === ''
    )
      return DefaultFeedbackImageString;
    return selectedFeedback.downloadedImage;
  }

  return (
    <Grid container>
      <Grid container item className={classes.onMobileVisible}>
        <TopMenuPanel
          selectedProjectId={props.selectedProjectId}
          viewMode={ViewMode.SimulationReview}
          onSideMenuIconClicked={props.onSimulationReviewBackButtonClicked}
        />
      </Grid>
      <Grid container item xs={12}>
        <Grid
          container
          item
          className={classes.onMobileVisible}
          style={{
            paddingTop: '80px'
          }}
        >
          <Grid
            container
            item
            style={{
              fontFamily: 'Prompt',
              fontWeight: '700',
              color: '#292336',
              fontSize: '2rem',
              lineHeight: '3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '0px 0px 11px 0px'
            }}
          >
            Simulation review
          </Grid>
          <Grid
            container
            item
            style={{
              fontFamily: 'Prompt',
              fontWeight: '700',
              color: '#292336',
              fontSize: '2rem',
              lineHeight: '3rem',
              display: 'flex',
              flexDirection: 'column',
              padding: '0px 0px 0px 16px'
            }}
          >
            <Grid
              style={{
                fontFamily: 'Prompt',
                fontWeight: '700',
                fontSize: '1.6rem',
                lineHeight: '2.4rem',
                color: '#292336'
              }}
            >
              {state.selectedSimulation.name}
            </Grid>

            <Grid
              style={{
                fontFamily: 'Prompt',
                fontWeight: '500',
                fontSize: '1.4rem',
                lineHeight: '2.1rem',
                color: '#A8A6AE',
                display: 'flex'
              }}
            >
              {project.name}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={classes.onMobileHidden}
          container
          item
          xs={12}
          justifyContent="space-between"
        >
          <LogoPanel logoPath={logoPath} altText={'ARBIRA'} />
          <Box
            style={{
              fontFamily: 'Prompt',
              fontWeight: 'bold',
              color: '#292336',
              fontSize: '2.8rem',
              lineHeight: '4.2rem',
              padding: '0 20px 0 20px',
              margin: '8px 0px 0px 0px'
            }}
          >
            Simulation review
          </Box>
          <Box className={classes.onMobileHidden}>
            <TopMenuGeneralActionPanel />
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ padding: '2rem 2rem 0px 2rem' }}
          className={classes.onMobileHidden}
        >
          <Grid container item xs={12} sm={8} style={{ display: 'flex' }}>
            <Grid item xs={1} sm={1} style={{ padding: '0px 1.5rem 0px 0px' }}>
              <IconButton
                color="default"
                disableFocusRipple={true}
                style={{
                  borderColor: 'lightgray',
                  boxShadow: 'none',
                  height: '30px',
                  width: '30px',
                  padding: '0px'
                }}
              >
                <BackIcon clicked={props.onSimulationReviewBackButtonClicked} />
              </IconButton>
            </Grid>

            <Grid
              style={{
                fontFamily: 'Prompt',
                fontWeight: 'bold',
                fontSize: '2.2rem',
                lineHeight: '3.3rem',
                color: '#292336',
                display: 'flex'
              }}
            >
              {project.name}
              <ChevronRightIcon />{' '}
            </Grid>

            <Grid
              style={{
                fontFamily: 'Prompt',
                fontWeight: 'bold',
                fontSize: '2.2rem',
                lineHeight: '3.3rem',
                color: '#292336',
                display: 'flex'
              }}
            >
              {state.selectedSimulation.name}
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={4} justifyContent="flex-end">
            <Box style={{ display: 'flex' }}>
              <Box style={{ paddingRight: '10px' }}>
                <InviteUserButton selectedProjectId={props.selectedProjectId} />
              </Box>

              <Box style={{ display: 'flex', paddingRight: '10px' }}>
                {project.sharedWith.slice(0, 3).map(sharedWith => (
                  <Grid item key={sharedWith.userId.toString()}>
                    <UserIcon
                      userId={sharedWith.userId}
                      firstNameOfUser={sharedWith.firstNameOfUser}
                      lastNameOfUser={sharedWith.lastNameOfUser}
                      imgPathOfUser={sharedWith.imgPathOfUser}
                      downloadedImage={sharedWith.downloadedImage}
                      emailOfUser={sharedWith.emailOfUser}
                      height={'30px'}
                      marginLeft={'-5px'}
                    />
                  </Grid>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            margin: '2rem 2rem 2rem 2rem',
            backgroundColor: '#292336'
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            sm={12}
            md={7}
            lg={8}
            style={{
              borderRadius: '4px 0px 0px 4px'
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                borderRadius: '4px 0px 0px 4px',
                paddingTop: '1rem'
              }}
            >
              <Grid
                item
                sm={12}
                style={{
                  margin: '0 1rem 1rem 1rem',
                  overflow: 'hidden'
                }}
              >
                <img
                  style={{
                    objectFit: 'contain',
                    width: 'calc(100%)',
                    height: 'calc(100%)',
                    maxHeight: '70vh'
                  }}
                  src={
                    'data:image/png;base64,' +
                    getFeedbackImage(selectedFeedback)
                  }
                  alt="feedback"
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  margin: '0px 10px 10px 10px',
                  height: '95px'
                }}
              >
                <Box paddingTop={'22px'}>
                  <Box style={{ position: 'relative' }}>
                    {state.selectedSimulation.feedbacks.map(feedback => (
                      <Box
                        key={feedback.id}
                        style={{
                          position: 'absolute',
                          left:
                            selectedFeedback !== undefined &&
                            feedback.id === selectedFeedback.id
                              ? 'calc(' +
                                (state.selectedSimulation.lengthInMilliSeconds >
                                0
                                  ? (feedback.positionInMilliseconds * 100) /
                                    state.selectedSimulation
                                      .lengthInMilliSeconds
                                  : 0) +
                                '% - 9px)'
                              : 'calc(' +
                                (state.selectedSimulation.lengthInMilliSeconds >
                                0
                                  ? (feedback.positionInMilliseconds * 100) /
                                    state.selectedSimulation
                                      .lengthInMilliSeconds
                                  : 0) +
                                '% - 4.5px)',
                          bottom:
                            selectedFeedback !== undefined &&
                            feedback.id === selectedFeedback.id
                              ? '-11.5px'
                              : '-5px'
                        }}
                      >
                        {selectedFeedback !== undefined &&
                        feedback.id === selectedFeedback.id ? (
                          <ActiveTimeSymbolIcon />
                        ) : (
                          <ButtonBase
                            disableRipple={true}
                            onClick={() => changeFeedbackSelection(feedback)}
                          >
                            <TimeSymbolIcon />
                          </ButtonBase>
                        )}
                      </Box>
                    ))}
                  </Box>
                  <WhiteHorizontalLine />
                </Box>
                <Box paddingTop={'5px'}>
                  <Box style={{ position: 'relative' }}>
                    <Box
                      style={{
                        position: 'absolute',
                        left: '0px',
                        bottom: '-20px'
                      }}
                    >
                      <Box
                        style={{
                          fontFamily: 'Prompt',
                          fontSize: '1.2rem',
                          lineHeight: '1.8rem',
                          color: '#FFFFFF',
                          opacity: 0.4
                        }}
                      >
                        00:00
                      </Box>
                    </Box>
                    {state.selectedSimulation.feedbacks.map(feedback =>
                      selectedFeedback !== undefined &&
                      feedback.id === selectedFeedback.id ? (
                        <Box
                          key={feedback.id}
                          style={{
                            position: 'absolute',
                            right:
                              'calc(' +
                              (100 -
                                (state.selectedSimulation.lengthInMilliSeconds >
                                0
                                  ? (feedback.positionInMilliseconds * 100) /
                                    state.selectedSimulation
                                      .lengthInMilliSeconds
                                  : 0)) +
                              '% - 16.5px)',
                            bottom: '-20px'
                          }}
                        >
                          <Box
                            style={{
                              fontFamily: 'Prompt',
                              fontSize: '1.2rem',
                              lineHeight: '1.8rem',
                              color: '#FFFFFF',
                              opacity: 0.4
                            }}
                          >
                            {formatSimulationLength(
                              feedback.positionInMilliseconds,
                              true
                            )}
                          </Box>
                        </Box>
                      ) : (
                        ''
                      )
                    )}
                    <Box
                      style={{
                        position: 'absolute',
                        right: '0px',
                        bottom: '-20px'
                      }}
                    >
                      <Box
                        style={{
                          fontFamily: 'Prompt',
                          fontSize: '1.2rem',
                          lineHeight: '1.8rem',
                          color: '#FFFFFF',
                          opacity: 0.4
                        }}
                      >
                        {formatSimulationLength(
                          state.selectedSimulation.lengthInMilliSeconds,
                          true
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Grid
                  container
                  style={{
                    paddingTop: '15px',
                    fontFamily: 'Prompt',
                    fontSize: '1.4rem',
                    lineHeight: '2.1rem',
                    color: '#FFFFFF'
                  }}
                  justifyContent="center"
                >
                  <Grid
                    style={{ width: '350px' }}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ButtonBase
                        disableRipple={true}
                        onClick={() =>
                          changeFeedbackSelection(
                            getPrevFeedback(
                              state.selectedSimulation.feedbacks,
                              selectedFeedback
                            )
                          )
                        }
                      >
                        <NavLeftIcon />
                        <Box paddingRight="15px">Prev</Box>
                      </ButtonBase>
                    </Grid>
                    <Grid item>
                      {getFeedbackTimeOverSimulationTimeString(
                        state.selectedSimulation.lengthInMilliSeconds,
                        selectedFeedback
                      )}
                    </Grid>
                    <Grid item>
                      <ButtonBase
                        disableRipple={true}
                        onClick={() =>
                          changeFeedbackSelection(
                            getNextFeedback(
                              state.selectedSimulation.feedbacks,
                              selectedFeedback
                            )
                          )
                        }
                      >
                        <Box paddingLeft="15px">Next</Box>
                        <NavRightIcon />
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*kommentek*/}
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={4}
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: '0px 4px 4px 0px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Grid item container style={{ padding: '10px 20px' }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontFamily: 'Prompt',
                    fontSize: '1.3rem',
                    lineHeight: '2rem',
                    letterSpacing: '0.02em',
                    color: '#292336',
                    fontWeight: 'bold',
                    paddingRight: '1.5rem'
                  }}
                >
                  {state.selectedSimulation.firstNameOfUser +
                    ' ' +
                    state.selectedSimulation.lastNameOfUser}
                </Grid>
                <Grid
                  item
                  style={{
                    fontFamily: 'Prompt',
                    fontSize: '1.3rem',
                    lineHeight: '2rem',
                    color: '#292336',
                    opacity: 0.3
                  }}
                >
                  {getTimePassed(
                    new Date(state.selectedSimulation.creationTime),
                    'uploaded '
                  )}
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <Grid
                  item
                  style={{
                    fontFamily: 'Prompt',
                    fontSize: '1.1rem',
                    lineHeight: '1.4rem',
                    color: '#292336'
                  }}
                >
                  {'Robot / Model: ' +
                    state.selectedSimulation.manufacturerOfRobot +
                    ' ' +
                    state.selectedSimulation.modelOfRobot}
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }}>
                <Grid
                  item
                  style={{
                    fontFamily: 'Prompt',
                    fontSize: '1.1rem',
                    lineHeight: '1.4rem',
                    color: '#292336'
                  }}
                >
                  Simulation description:
                  <br />
                  {state.selectedSimulation.description}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.commentSectionStyle}
              xs={12}
              style={{
                borderTop: '1px solid #A8A6AE',
                borderBottom: '1px solid #A8A6AE',
                overflow: 'scroll',
                maxHeight: '60vh'
              }}
            >
              <Grid
                style={{
                  minHeight: 'calc(100% - 20px)',
                  marginLeft: '20px',
                  borderLeft: '1px solid #A8A6AE',
                  padding: '10px 20px'
                }}
              >
                {state.selectedSimulation.feedbacks.map(feedback => (
                  <FeedbackItem
                    key={feedback.id}
                    feedback={feedback}
                    selectedfeedbackId={
                      selectedFeedback !== undefined ? selectedFeedback.id : ''
                    }
                    onJumpToFeedback={() => changeFeedbackSelection(feedback)}
                    onAddCommentForFeedback={addCommentForFeedback}
                    projectId={project.id}
                    simulationId={state.selectedSimulation.id}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'end' }}>
              <Grid
                item
                container
                xs={12}
                style={{
                  padding: '10px 20px',
                  height: '6rem',
                  marginBottom: '10px'
                }}
              >
                <CustomInput
                  placeholder={commentPlaceHolder}
                  style={{ height: '4rem', width: '100%' }}
                  value={newCommentForSelectedFeedback}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setNewCommentForSelectedFeedback(e.target.value)}
                  onKeyUp={(e: { keyCode: number }) =>
                    e.keyCode === 13
                      ? addCommentForSelectedFeedback()
                      : e.keyCode === 27
                      ? setNewCommentForSelectedFeedback('')
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
