import React from 'react';
import { SearchPanelProps } from '../../types/interfaces';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import {
  searchPanel,
  searchButton,
  searchText,
  searchPanelDark,
  searchButtonDark,
  searchTextDark
} from '../../styles/styles';

import {
  Grid,
  ButtonBase,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import { SearchIcon } from './IconImages';

export function SearchPanel(searchPanelProps: SearchPanelProps) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      panelStyle: {
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
        [theme.breakpoints.up('md')]: {
          width: '90%'
        }
      }
    })
  );
  const classes = useStyles();

  return (
    <Grid container item xs={12}>
      <Paper style={searchPanel} className={classes.panelStyle}>
        <ButtonBase style={searchButton} disableRipple={true}>
          <SearchIcon height="auto" />
        </ButtonBase>
        <InputBase
          fullWidth={true}
          style={searchText}
          placeholder={searchPanelProps.placeholder}
          value={searchPanelProps.searchText}
          onChange={e => searchPanelProps.onSearchTextChanged(e.target.value)}
        />
      </Paper>
    </Grid>
  );
}

export function SearchPanelDark(searchPanelProps: SearchPanelProps) {
  return (
    <Grid container item xs={12}>
      <Paper style={searchPanelDark}>
        <ButtonBase style={searchButtonDark} disableRipple={true}>
          <SearchIcon height="2rem" />
        </ButtonBase>
        <InputBase
          fullWidth={true}
          style={searchTextDark}
          placeholder={searchPanelProps.placeholder}
          value={searchPanelProps.searchText}
          onChange={e => searchPanelProps.onSearchTextChanged(e.target.value)}
        />
      </Paper>
    </Grid>
  );
}
