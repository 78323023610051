import React, { useContext, useState } from 'react';
import { DataViewActionPanelProps, ViewMode } from '../../types/interfaces';
import { Grid, Box, Hidden } from '@material-ui/core';
import {
  topMenuGeneralActionPanelStyle,
  dataViewActionPanelNewRobotSimulationButtonStyle,
  projectNameStyle
} from '../../styles/styles';
import { MainButton } from '../reusable/Buttons';
import { SearchPanel } from '../reusable/SearchPanel';
import { NewSimulationModal } from '../simulation/NewSimulationModal';
import { AppContext } from '../../context/context';
import { getProject } from '../../helper/helper';

export function DataViewActionPanel(props: DataViewActionPanelProps) {
  const { state } = useContext(AppContext);
  let selectedProject = getProject(state.projectList, props.selectedProjectId);
  const [newSimulationPopupOpen, setNewSimulationPopupOpen] = useState(false);

  let buttonWithModal = null;
  if (props.viewMode === ViewMode.ProjectPage) {
    buttonWithModal = (
      <Box>
        <MainButton
          textValue="NEW SIMULATION"
          height="3.2rem"
          onclick={() => setNewSimulationPopupOpen(true)}
          disabled={selectedProject === undefined}
        />
        <NewSimulationModal
          selectedProjectId={props.selectedProjectId}
          isOpen={newSimulationPopupOpen}
          handleClose={() => setNewSimulationPopupOpen(false)}
        />
      </Box>
    );
  }

  return (
    <Grid container style={topMenuGeneralActionPanelStyle}>
      <Hidden mdUp>
        <Grid
          container
          item
          xs={12}
          sm={12}
          style={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box fontWeight={700} style={projectNameStyle}>
            {selectedProject !== undefined
              ? selectedProject.name
              : 'Project page example'}
          </Box>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          item
          xs={12}
          sm={12}
          style={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              style={{
                width: '900px'
              }}
            >
              {buttonWithModal}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '24px'
            }}
          >
            <SearchPanel
              searchText={props.searchText}
              placeholder={'Search...'}
              onSearchTextChanged={props.onSearchTextChanged}
            />
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smDown>
        <Grid container item xs={12} sm={12}>
          <Grid container item xs={12} sm={8}>
            <SearchPanel
              searchText={props.searchText}
              placeholder={'Search...'}
              onSearchTextChanged={props.onSearchTextChanged}
            />
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="flex-end">
            <Grid style={dataViewActionPanelNewRobotSimulationButtonStyle}>
              {buttonWithModal}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
