import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AzureAD, { AuthenticationState } from 'react-aad-msal';
import { authProvider } from './authProvider';
import { Arbira } from './pages/Arbira';
import { AppProvider } from './context/context';
import { Redirect } from './components/reusable/Redirect';

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin={true}>
    {({
      authenticationState
    }: {
      authenticationState: AuthenticationState;
    }) => {
      switch (authenticationState) {
        case AuthenticationState.Authenticated:
          return (
            <AppProvider>
              <Arbira />
            </AppProvider>
          );
        case AuthenticationState.Unauthenticated:
        case AuthenticationState.InProgress:
          return <Redirect />;
      }
    }}
  </AzureAD>,
  document.getElementById('root')
);
