import React from 'react';
import { Grid } from '@material-ui/core';
import { SideMenuItemMenuDialogProps } from '../../types/interfaces';
import { LightMenuButtonHover } from '../reusable/Buttons';
import { StyledMenuTopRight } from '../reusable/StyledMenu';

export function SideMenuItemMenuDialog(props: SideMenuItemMenuDialogProps) {
  return (
    <StyledMenuTopRight
      keepMounted
      open={props.isOpen}
      onClose={props.handleClose}
      anchorEl={props.menuAnchor}
    >
      <Grid
        style={{ width: '230px', padding: '0px 10px' }}
        xs={12}
        container
        item
      >
        <Grid xs={12} item style={{ padding: '5px 0px' }}>
          <LightMenuButtonHover
            onclick={props.handleEditProject}
            textValue="Edit"
            height="3.3rem"
          />
        </Grid>
        <Grid xs={12} item style={{ padding: '5px 0px' }}>
          <LightMenuButtonHover
            onclick={props.handleShareProject}
            textValue="Share"
            height="3.3rem"
          />
        </Grid>
        <Grid xs={12} item style={{ padding: '5px 0px' }}>
          <LightMenuButtonHover
            onclick={props.handleArchiveProject}
            textValue="Archive"
            height="3.3rem"
          />
        </Grid>
      </Grid>
    </StyledMenuTopRight>
  );
}
