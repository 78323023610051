import { Grid, Box, makeStyles, createStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { UploadButton, SecondaryUploadButton, CancelButton } from './Buttons';
import {
  ModalUploadGridProps,
  UploadViewState,
  MoadlUploadGridSupportedFormats
} from '../../types/interfaces';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      position: 'relative',
      height: '4rem',
      fontFamily: 'OpticianSans',
      fontSize: '1.4rem',
      letterSpacing: '0.06em',
      cursor: 'pointer',
      //fontWeight: "bold",
      color: 'rgb(41, 35, 54)',
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '2px',
      border: '1px solid #000000',
      margin: '10px 0px 17px 0px'
    },
    outer: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      overflow: 'hidden'
    },
    inner: {
      color: 'rgb(255, 255, 255)',
      backgroundColor: 'rgb(41, 35, 54)',
      height: '3.8rem'
      //borderRadius: "2px"
      //border: "1px solid #000000"
    },
    fisnihed: {
      position: 'relative',
      height: '4rem',
      fontFamily: 'OpticianSans',
      fontSize: '1.4rem',
      letterSpacing: '0.06em',
      cursor: 'pointer',
      //fontWeight: "bold",
      color: 'rgb(255, 255, 255)',
      backgroundColor: 'rgb(41, 35, 54)',
      borderRadius: '2px',
      border: '1px solid #000000',
      margin: '10px 0px 17px 0px',
      overflow: 'hidden'
    }
  })
);

export function ModalUploadGrid(props: ModalUploadGridProps) {
  let progressButton: HTMLElement | null = document.getElementById(
    'progressButton'
  );

  const [progressButtonWidth, setProgressButtonWidth] = useState(
    progressButton ? progressButton.clientWidth : 0
  );
  React.useEffect(() => {
    function handleResize() {
      setProgressButtonWidth(progressButton ? progressButton.clientWidth : 0);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const divRef = React.useRef<HTMLDivElement>(null);

  const classes = useStyles();

  switch (props.uploadViewState) {
    case UploadViewState.Uploading:
      return (
        <Grid item xs={12} container justifyContent="center">
          <Grid
            id="progressButton"
            ref={divRef}
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ overflow: 'hidden' }}
            >
              {props.fileName} {props.uploadProcessValue}%
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              className={classes.outer}
              style={{ width: props.uploadProcessValue + '%' }}
            >
              <Grid
                className={classes.inner}
                container
                alignItems="center"
                style={{
                  width:
                    progressButtonWidth > 0
                      ? progressButtonWidth
                      : divRef.current
                      ? divRef.current.clientWidth
                      : 0
                }}
              >
                <Box
                  style={{
                    display: 'block',
                    width:
                      progressButtonWidth > 0
                        ? progressButtonWidth
                        : divRef.current
                        ? divRef.current.clientWidth
                        : 0,
                    textAlign: 'center'
                  }}
                >
                  {props.fileName} {props.uploadProcessValue}%
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box style={{ width: '95px', paddingBottom: '19px' }}>
            <CancelButton
              textValue="CANCEL"
              height="22px"
              onclick={props.onFileUploadCanceled}
            ></CancelButton>
          </Box>
          <SupportedFileFormats formats={props.supportedFormats} />
        </Grid>
      );
    case UploadViewState.Finished:
      return (
        <Grid item xs={12} container justifyContent="center">
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            className={classes.fisnihed}
          >
            {props.fileName}
          </Grid>
          <Box style={{ width: '135px' }}>
            <SecondaryUploadButton
              textValue="UPLOAD NEW"
              height="22px"
              onChange={props.onChange}
            />
          </Box>
          <SupportedFileFormats formats={props.supportedFormats} />
        </Grid>
      );
    default:
      return (
        <Box>
          <Grid item xs={12}>
            <UploadButton
              textValue={props.uploadText}
              height="58px"
              onChange={props.onChange}
            />
          </Grid>
          <SupportedFileFormats formats={props.supportedFormats} />
        </Box>
      );
  }
}

function SupportedFileFormats(
  supportedFormats: MoadlUploadGridSupportedFormats
) {
  return (
    <Grid item xs={12} style={{ paddingTop: '20px' }}>
      <Box
        style={{
          fontFamily: 'Prompt',
          fontSize: '1.4rem',
          lineHeight: '2.1rem',
          color: '#292336',
          textAlign: 'center',
          alignItems: 'center'
        }}
      >
        Supported file formats:
        <br />
        {supportedFormats.formats}
      </Box>
    </Grid>
  );
}
