import { DataViewPanel } from '../components/dataViewPanel/DataViewPanel';
import React, { useContext, useEffect, useState } from 'react';
import { FileType, ViewMode } from '../types/interfaces';
import '../index.css';
import { Box, makeStyles, Snackbar } from '@material-ui/core';
import { displayFlex } from '../styles/styles';
import { SideMenuDrawerPanel } from '../components/sideMenu/SideMenuDrawerPanel';
import TopMenuPanel from '../components/topMenu/TopMenuPanel';
import { SimulationReview } from '../components/simulation/SimulationReview';
import { AppContext } from '../context/context';
import { Types } from '../reducers/reducers';
import { authProvider } from '../authProvider';
import {
  DownloadFile,
  GetProjectsOfUser,
  GetSimulation,
  Login
} from '../helper/apiHelper';
import Alert from '@material-ui/lab/Alert';
import { getProject } from '../helper/helper';
import {
  RefreshCommenterUserIcon,
  RefreshSharedWithUserImage,
  RefreshSimulationCoverImage,
  SetFeedbackImage
} from '../helper/imageHelper';

export function Arbira() {
  const { state, dispatch } = useContext(AppContext);
  const [selectedProjectId, setSelectedProjectId] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [viewMode, setViewMode] = useState(ViewMode.ProjectPage);

  const useStyles = makeStyles({
    myAlert: {
      '& .MuiAlert-message': {
        fontSize: '1.4rem'
      }
    }
  });

  const classes = useStyles();

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: Types.SetSnackbar,
      payload: {
        snackbarData: { open: false, message: '', severity: 'info' }
      }
    });
  };

  useEffect(() => {
    const loginUser = async () => {
      let user = await Login(
        authProvider.getAccount().idToken.family_name,
        authProvider.getAccount().idToken.given_name,
        authProvider.getAccount().idToken.emails[0],
        dispatch
      );
      dispatch({
        type: Types.RefreshUser,
        payload: {
          user: user
        }
      });
      dispatch({
        type: Types.RefreshProjects,
        payload: {
          projectList: await GetProjectsOfUser(user.id, dispatch)
        }
      });
      if (user.imgPath !== '') {
        const b64 = await DownloadFile(
          user.imgPath,
          FileType.UserImage,
          dispatch
        );
        dispatch({
          type: Types.SetUserImage,
          payload: {
            base64ImgString: b64 as string
          }
        });
      }
    };
    loginUser();
  }, [dispatch]);

  function handleSearchTextChanged(newSearchText: string) {
    setSearchText(newSearchText);
  }

  function handleProjectSelectionChanged(newSelection: string) {
    setSelectedProjectId(newSelection);
    let project = getProject(state.projectList, newSelection);
    RefreshSimulationCoverImage(project, dispatch, state.downloadedImages);
    RefreshSharedWithUserImage(project, dispatch, state.downloadedImages);
    if (sideMenuOpen) setSideMenuOpen(false);
  }

  function handleSideMenuOpenCloseAction() {
    setSideMenuOpen(!sideMenuOpen);
  }

  function handleViewModeChange(newViewMode: ViewMode) {
    setViewMode(newViewMode);
    if (sideMenuOpen) setSideMenuOpen(false);
  }

  async function handleViewModeChangeToSimulationReview(
    newViewMode: ViewMode,
    simulationId: string
  ) {
    let selectedSimulation = await GetSimulation(simulationId, dispatch);
    dispatch({
      type: Types.SetSelectedSimulation,
      payload: {
        simulation: selectedSimulation
      }
    });
    RefreshCommenterUserIcon(
      selectedSimulation,
      dispatch,
      state.downloadedImages
    );
    if (selectedSimulation.feedbacks.length > 0)
      SetFeedbackImage(
        selectedSimulation.feedbacks[0],
        dispatch,
        state.downloadedImages
      );
    setViewMode(newViewMode);
  }

  function handleSimulationreviewBackButtonClicked() {
    setViewMode(ViewMode.ProjectPage);
  }

  let content = <React.Fragment></React.Fragment>;
  let snackbar = (
    <Snackbar
      autoHideDuration={4000}
      open={state.snackbarData.open}
      onClose={handleSnackbarClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <div style={{ border: '1px solid #A8A6AE', borderRadius: 5 }}>
        <Alert
          className={classes.myAlert}
          onClose={handleSnackbarClose}
          severity={state.snackbarData.severity}
          variant="standard"
        >
          {state.snackbarData.message}
        </Alert>
      </div>
    </Snackbar>
  );
  switch (viewMode) {
    case ViewMode.SimulationReview:
      content = (
        <Box style={displayFlex}>
          <SimulationReview
            selectedProjectId={selectedProjectId}
            onSimulationReviewBackButtonClicked={
              handleSimulationreviewBackButtonClicked
            }
          />
          {snackbar}
        </Box>
      );
      break;
    default:
      content = (
        <Box style={displayFlex}>
          <TopMenuPanel
            selectedProjectId={selectedProjectId}
            viewMode={viewMode}
            onSideMenuIconClicked={handleSideMenuOpenCloseAction}
          />
          <SideMenuDrawerPanel
            selectedProjectId={selectedProjectId}
            sideMenuOpen={sideMenuOpen}
            onProjectSelectionChanged={handleProjectSelectionChanged}
            onSideMenuOpenCloseAction={handleSideMenuOpenCloseAction}
            onViewModeChange={handleViewModeChange}
          />
          <DataViewPanel
            searchText={searchText}
            onSearchTextChanged={handleSearchTextChanged}
            selectedProjectId={selectedProjectId}
            viewMode={viewMode}
            onViewModeChangeToSimulationReview={
              handleViewModeChangeToSimulationReview
            }
          />
          {snackbar}
        </Box>
      );
      break;
  }
  return content;
}
