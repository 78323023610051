import React, { useContext, useState } from 'react';
import { ButtonBase, Grid } from '@material-ui/core';
import { PlusIcon } from '../reusable/IconImages';
import { InviteUserButtonProps } from '../../types/interfaces';
import { InviteUserModal } from './InviteUserModal';
import { getProject } from '../../helper/helper';
import { AppContext } from '../../context/context';

export function InviteUserButton(props: InviteUserButtonProps) {
  const { state } = useContext(AppContext);
  let project = getProject(state.projectList, props.selectedProjectId);
  const [popupOpen, setPopupOpen] = useState(false);

  function handlePopupOpen() {
    setPopupOpen(true);
  }

  function handlePopupClose() {
    setPopupOpen(false);
  }

  return (
    <Grid item>
      <ButtonBase
        color="default"
        disableRipple={true}
        onClick={handlePopupOpen}
      >
        <PlusIcon />
      </ButtonBase>
      <InviteUserModal
        projectId={props.selectedProjectId}
        sharedWith={project.sharedWith}
        isOpen={popupOpen}
        handleClose={handlePopupClose}
      />
    </Grid>
  );
}
