import React, { useState } from 'react';
import {
  SimulationBase,
  SimulationItemProps,
  ViewMode
} from '../../types/interfaces';
import {
  ButtonBase,
  Box,
  Grid,
  Card,
  CardHeader,
  Typography,
  CardContent
} from '@material-ui/core';
import {
  simulationItemStyle,
  simulationItemNameStyle,
  simulationItemCommentCountStyle,
  simulationItemTextStyle,
  simulationItemCardAction,
  simulationItemMoreButton,
  simulationItemTimeBox,
  simulationItemCardCardContent,
  simulationItemDescription,
  simulationItemHoverStyle
} from '../../styles/styles';
import { CommentIcon, MoreIcon } from '../reusable/IconImages';
import { SecondaryButton } from '../reusable/Buttons';
import { SimulationSettingsMenuDialog } from './SimulationSettingsMenuDialog';
import { SimulationEditModal } from './SimulationEditModal';
import { formatSimulationLength } from '../../helper/helper';
import { DefaultSimulationImageString } from './DefaultSimulationImageString';

export function SimulationItem(props: SimulationItemProps) {
  const [mouseOver, setMouseOver] = useState(false);
  const [openMenuModal, setOpenModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [simulationUpdateOpen, setSimulationUpdateOpen] = useState(false);

  const handleOpenMenuModal = (target: any) => {
    setOpenModal(true);
    setMenuAnchor(target);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSimulationUpdatePressed = () => {
    handleClose();
    setSimulationUpdateOpen(true);
  };

  const handleSimulationUpdateClose = () => {
    setSimulationUpdateOpen(false);
  };

  function getSimulationSnapshot(simulation: SimulationBase) {
    // set the default image if there is nothing set yet
    if (!simulation.snapshotLoaded) return DefaultSimulationImageString;

    return simulation.downloadedImage;
  }

  return (
    <Card
      style={mouseOver ? simulationItemHoverStyle : simulationItemStyle}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <CardHeader
        style={
          mouseOver
            ? {
                height: 'auto',
                padding: '0px',
                background:
                  'url(data:image/png;base64,' +
                  getSimulationSnapshot(props.simulation) +
                  ')',
                opacity: 1
              }
            : {
                height: 'auto',
                padding: '0px',
                background:
                  'url(data:image/png;base64,' +
                  getSimulationSnapshot(props.simulation) +
                  ')',
                opacity: 0.5
              }
        }
        action={
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
            style={simulationItemCardAction}
          >
            <Grid>
              <ButtonBase
                style={simulationItemMoreButton}
                disableRipple={true}
                onClick={event => {
                  let target = event.currentTarget;
                  handleOpenMenuModal(target);
                }}
              >
                <MoreIcon />
              </ButtonBase>
              <SimulationEditModal
                isOpen={simulationUpdateOpen}
                handleClose={handleSimulationUpdateClose}
                projectId={props.projectId}
                simulation={props.simulation}
              />
              <SimulationSettingsMenuDialog
                isOpen={openMenuModal}
                menuAnchor={menuAnchor}
                handleClose={handleClose}
                handleSimulationUpdatePressed={handleSimulationUpdatePressed}
              />
            </Grid>
            <Grid
              container
              style={simulationItemTimeBox}
              justifyContent="center"
            >
              <Box>
                {formatSimulationLength(
                  props.simulation.lengthInMilliSeconds,
                  false
                )}
              </Box>
            </Grid>
          </Grid>
        }
      />
      <CardContent style={simulationItemCardCardContent}>
        <Grid container item xs={12}>
          <Grid item xs={9}>
            <Box style={simulationItemNameStyle} fontWeight={800}>
              {props.simulation.name}
            </Box>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <ButtonBase disableRipple={true}>
              <CommentIcon />
              <Box style={simulationItemCommentCountStyle}>
                {props.simulation.totalFeedbackCount}
              </Box>
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Box style={simulationItemTextStyle}>
            {props.simulation.firstNameOfUser +
              ' ' +
              props.simulation.lastNameOfUser}{' '}
            &bull;{' '}
            {new Date(props.simulation.creationTime).toLocaleDateString()}
          </Box>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={8}>
            <Typography noWrap style={simulationItemDescription}>
              {props.simulation.description}
            </Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Box style={{ width: '100px' }}>
              <SecondaryButton
                textValue="OPEN"
                height="22px"
                onclick={() =>
                  props.onViewModeChangeToSimulationReview(
                    ViewMode.SimulationReview,
                    props.simulation.id
                  )
                }
              ></SecondaryButton>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
